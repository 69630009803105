import * as R from 'ramda';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { arrayOf, func, shape, string } from 'prop-types';
import { entityToOptionByLabelPath } from '@poly/client-utils';

import {
  isAllOption,
  rejectAllOption,
  ALL_SELECTED_OPTION,
} from '../SpendReportPage/helpers.js';
import { BaseSelectComponent } from '../SpendReportPage/PropertySelect.js';

const assetTypesQuery = gql`
  query assetTypesQuery {
    assetTypes {
      _id
      name
    }
  }
`;

const SelectWrapper = styled.div`
  width: ${R.propOr('300px', 'width')};
  position: relative;
`;

const ALL_ASSET_TYPE_OPTIONS = {
  ...ALL_SELECTED_OPTION,
  label: 'All asset types',
};

// getAssetTypeOptions :: AssetsQueryResult -> [Option]
const getAssetTypeOptions = R.compose(
  R.prepend(ALL_ASSET_TYPE_OPTIONS),
  R.map(entityToOptionByLabelPath(['name'])),
  R.propOr([], 'assetTypes'),
);

export function AssetsTypeSelect({ value, onChange, width }) {
  const selectRef = useRef(null);

  const { data, loading } = useQuery(assetTypesQuery);

  const onChangeHandler = (options, { option }) => {
    if (isAllOption(option)) {
      return onChange([ALL_ASSET_TYPE_OPTIONS]);
    }
    if (options) {
      return onChange(rejectAllOption(options));
    }
    return onChange([]);
  };

  const options = getAssetTypeOptions(data);

  return (
    <SelectWrapper width={width}>
      <BaseSelectComponent
        placeholder="Select Asset Types"
        noResultsText="No Asset Types Found"
        onChange={onChangeHandler}
        onSelectResetsInput={false}
        isLoading={loading}
        options={options}
        ref={selectRef}
        value={value}
        isMulti
      />
    </SelectWrapper>
  );
}

AssetsTypeSelect.propTypes = {
  value: arrayOf(
    shape({
      value: string.isRequired,
      label: string.isRequired,
    }),
  ),
  onChange: func.isRequired,
  width: string,
};
