import {
  func,
  bool,
  shape,
  string,
  number,
  arrayOf,
  objectOf,
} from 'prop-types';
import * as R from 'ramda';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useOutSidebarContext, validateFormData } from '@poly/client-utils';
import { Button, Grid, HeadingH3, TextArea, S, L } from '@poly/site-book';
import { insertParamsIntoURL, pathEqLegacy } from '@poly/utils';
import { useNavigate } from '@poly/client-routing';
import {
  propertiesForSelectQuery,
  PropertySelectorBase,
  addProjectMutation,
  ButtonLoader,
  SelectBase,
  FormRows,
} from '@poly/site-ui';
import {
  ProjectSpendTypes,
  WorkOrderPriority,
  DESC_SORT_ORDER,
  ESFM_INFO,
  ProjectType,
} from '@poly/constants';

import { routes } from '../../routes.js';
import { useCurrentClientConfig } from '../../hooks/useCurrentClientConfig.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

export const addProjectFormId = 'addProjectFormId';

const CURRENT_REQUESTOR_QUERY = gql`
  query CURRENT_REQUESTOR_QUERY {
    me {
      _id
    }
  }
`;

function SimpleTitle({ label }) {
  return <S type="title">{label}</S>;
}

SimpleTitle.propTypes = { label: string };

const clientPropTypes = {
  configs: shape({
    projectPriorities: arrayOf(
      shape({
        amount: number,
        id: string,
        priority: string,
        unit: string,
      }),
    ),
  }),
};

// preparePriorityOptionsByClient :: Client -> [SelectOption]
const preparePriorityOptionsByClient = R.compose(
  R.map(
    R.applySpec({
      value: R.prop('id'),
      label: ({ priority, amount, unit }) => `${priority} (${amount} ${unit})`,
    }),
  ),
  R.pathOr([], ['configs', 'projectPriorities']),
);

function PrioritySelector({ client, ...props }) {
  const selectProps = {
    ...props,
    options: preparePriorityOptionsByClient(client),
    onSelectResetsInput: false,
    valueRenderer: SimpleTitle,
    optionRenderer: SimpleTitle,
    placeholder: 'Start Typing Priority',
  };

  return <SelectBase {...selectProps} />;
}

PrioritySelector.propTypes = {
  client: shape(clientPropTypes),
};

const EmergencyMessageWrapperS = styled.div`
  text-align: center;
`;

// checkIsPriorityEmergency :: FormFieldProps -> Boolean
// FormFieldProps = { value: Option }
const checkIsPriorityEmergency = pathEqLegacy(
  ['priority', 'value'],
  WorkOrderPriority.EMERGENCY,
);

function EmergencyMessage() {
  const displayPhoneNumber = R.replace(/-/g, '.', ESFM_INFO.telephone);
  const hrefPhoneNumber = R.replace(/-/g, '', ESFM_INFO.telephone);

  return (
    <EmergencyMessageWrapperS>
      <S type="content">
        Emergency requests are handled with the upmost priority. Please call our
        national service center at{' '}
        <L href={`tel:${hrefPhoneNumber}`}>{displayPhoneNumber}</L>
      </S>
    </EmergencyMessageWrapperS>
  );
}

const useSelectorPropertiesQuery = (searchText) => {
  const clientId = useCurrentClientId();
  const { data, loading } = useQuery(propertiesForSelectQuery, {
    variables: {
      input: {
        size: 1000,
        searchTerm: searchText,
        sort: [{ createdAt: DESC_SORT_ORDER }],
        query: { term: { clientId } },
      },
    },
    skip: !clientId,
  });
  return {
    properties: R.pathOr([], ['searchProperties', 'hits'], data),
    loading,
  };
};

function PropertySelector(props) {
  const [searchText, setSearchText] = useState('');
  const { properties, loading } = useSelectorPropertiesQuery(searchText);
  return (
    <PropertySelectorBase
      properties={properties}
      loading={loading}
      setPropertySearch={setSearchText}
      {...props}
    />
  );
}

const FormBase = styled.form`
  margin-top: 15px;
`;

function AddProjectFormBase({
  client,
  errors,
  values,
  touched,
  loading,
  closeSidebar,
  handleSubmit,
}) {
  const isEmergency = checkIsPriorityEmergency(values);

  return (
    <FormBase id={addProjectFormId} onSubmit={handleSubmit}>
      <Grid columns="120px minmax(200px, 350px)" margin={30} simple>
        <FormRows
          items={[
            {
              title: {
                value: 'Priority',
                props: ['required'],
              },
              error: touched.priority ? errors.priority : '',
              item: (
                <Field name="priority">
                  {({ input, meta }) => (
                    <PrioritySelector
                      invalid={meta.touched && meta.invalid}
                      client={client}
                      {...input}
                    />
                  )}
                </Field>
              ),
            },
            ...(isEmergency
              ? [
                  {
                    title: { value: '', style: { padding: 0 } },
                    item: (
                      <Field name="emergency">
                        {() => <EmergencyMessage {...values} />}
                      </Field>
                    ),
                  },
                ]
              : [
                  {
                    title: {
                      value: 'Description',
                      props: ['required'],
                    },
                    error: touched.description ? errors.description : '',
                    item: (
                      <Field name="description">
                        {({ input, meta }) => (
                          <TextArea
                            name="description"
                            placeholder="Description"
                            invalid={meta.touched && meta.invalid}
                            {...input}
                          />
                        )}
                      </Field>
                    ),
                  },
                  {
                    title: {
                      value: 'Property',
                      props: ['required'],
                    },
                    error: touched.propertyId ? errors.propertyId : '',
                    item: (
                      <Field name="propertyId">
                        {({ input, meta }) => (
                          <PropertySelector
                            name="propertyId"
                            placeholder="Property"
                            invalid={meta.touched && meta.invalid}
                            {...input}
                          />
                        )}
                      </Field>
                    ),
                  },
                ]),
          ]}
        />
      </Grid>
      {!isEmergency && (
        <FormButtonsContainer>
          <Button
            type="reset"
            mode="gray"
            onClick={closeSidebar}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button
            form={addProjectFormId}
            type="submit"
            mode="orange"
            disabled={loading}
          >
            {loading && <ButtonLoader />}
            <S type="title">Create WO</S>
          </Button>
        </FormButtonsContainer>
      )}
    </FormBase>
  );
}

AddProjectFormBase.propTypes = {
  loading: bool,
  errors: objectOf(string),
  touched: objectOf(string),
  handleSubmit: func.isRequired,
  closeSidebar: func.isRequired,
  values: shape({ priority: objectOf(string) }).isRequired,
  client: shape(clientPropTypes),
};

const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
  button:not(:last-child) {
    margin-right: 15px;
  }
`;

// formatMutationInput :: EntitiesInfo -> FormValues -> CreateProjectInput
// EntitiesInfo = { client: Client, requestorData: UserQueryResponse }
const formatMutationInput = ({ client, requestorData }) =>
  R.applySpec({
    clientId: R.always(client._id),
    description: R.prop('description'),
    type: R.always(ProjectType.WORK_ORDER),
    priority: R.path(['priority', 'value']),
    propertyId: R.path(['propertyId', 'value']),
    spendType: R.always(ProjectSpendTypes.REACTIVE_REPAIR),
    serviceTypeId: R.compose(
      R.path(['configs', 'clientPortalApp', 'projectServiceType', '_id']),
      R.always(client),
    ),
    contactId: R.compose(R.path(['me', '_id']), R.always(requestorData)),
  });

const validate = validateFormData([
  {
    path: ['priority'],
    validators: [[R.identity, 'Priority is required']],
  },
  {
    path: ['description'],
    validators: [[R.identity, 'Description is required']],
  },
  {
    path: ['propertyId'],
    validators: [[R.identity, 'Property is required']],
  },
]);

// getCreatedProjectId :: CreateProjectInput -> ID
const getCreatedProjectId = R.path([
  'data',
  'createProject',
  'project',
  'projectId',
]);

export function AddProjectForm() {
  const navigate = useNavigate();
  const client = useCurrentClientConfig();
  const { closeOutSidebar } = useOutSidebarContext();
  const [mutate, { loading }] = useMutation(addProjectMutation);
  const { data: requestorData } = useQuery(CURRENT_REQUESTOR_QUERY);

  const closeSidebar = () => closeOutSidebar(addProjectFormId);

  const onSubmit = async (values) => {
    const res = await mutate({
      variables: {
        input: formatMutationInput({ client, requestorData })(values),
      },
    });
    toast.success('Work Order Successfully Created');
    closeSidebar();
    navigate(
      insertParamsIntoURL(
        { projectId: getCreatedProjectId(res) },
        routes.projectDetails,
      ),
    );
  };

  return (
    <>
      <HeadingH3 lighter>Create New Work Order</HeadingH3>
      <Form
        loading={loading}
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={false}
        closeSidebar={closeSidebar}
        render={AddProjectFormBase}
        client={client}
      />
    </>
  );
}
