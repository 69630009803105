import * as R from 'ramda';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from '@poly/utils';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from '@poly/client-utils';

import { BaseSelectComponent } from '../pages/SpendReportPage/PropertySelect.js';

const SelectWrapper = styled.div`
  width: 200px;
  position: relative;
`;

const SEARCH_REQUESTORS_QUERY = gql`
  query SEARCH_REQUESTORS_QUERY($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        _id
        profile {
          fullName
        }
      }
    }
  }
`;

// getRequestorSelectOptions :: { searchUsers: { hits: [User] } } -> SelectOptions
const getRequestorSelectOptions = R.compose(
  R.map(entityToOptionByLabelPath(['profile', 'fullName'])),
  R.pathOr([], ['searchUsers', 'hits']),
);

export function RequestorSelect(props) {
  const selectRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const setSearchTermDebounced = useCallback(debounce(500)(setSearchTerm), []);

  const searchInput = useMemo(() => ({ searchTerm }), [searchTerm]);

  const { data, loading } = useQuery(SEARCH_REQUESTORS_QUERY, {
    variables: { searchInput },
    fetchPolicy: 'network-only',
    skip: !searchTerm,
  });

  const selectProps = {
    ref: selectRef,
    options: getRequestorSelectOptions(data),
    isLoading: loading,
    isClearable: true,
    placeholder: 'Start typing users',
    noResultsText: 'No Users Found',
    onInputChange: setSearchTermDebounced,
    closeMenuOnSelect: true,
    blurInputOnSelect: true,
    onSelectResetsInput: false,
    ...props,
  };

  return (
    <SelectWrapper>
      <BaseSelectComponent {...selectProps} />
    </SelectWrapper>
  );
}
