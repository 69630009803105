import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY } from '@poly/client-utils';
import {
  prepareHierarchyNotDuplicatedProperties,
  assocPropsForPropertyReportRecursive,
  isNilOrEmpty,
  pathEqLegacy,
} from '@poly/utils';
import {
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
  ClientSpendReportTypes,
} from '@poly/constants';

import { toMutationInput } from './helpers.js';

// getSpendReportData :: (ID, Object) -> SpendReportQueryData -> [PropertyHierarchySpendReportResult]
const prepareSpendReport = (hierarchyId, additionalReportProps) =>
  R.compose(
    R.map(assocPropsForPropertyReportRecursive(additionalReportProps)),
    R.reject(R.isEmpty),
    R.map(R.omit(['filterHierarchyId'])),
    R.ifElse(
      R.either(
        R.pathSatisfies(isNilOrEmpty, ['0', 'filterHierarchyId']),
        pathEqLegacy(
          ['0', 'filterHierarchyId'],
          DEFAULT_PROPERTY_HIERARCHY_OPTION,
        ),
      ),
      R.compose(
        R.map(R.over(R.lensProp('properties'), R.uniqBy(R.prop('propertyId')))),
        R.filter(R.propSatisfies(R.isNil, '_id')),
      ),
      prepareHierarchyNotDuplicatedProperties(hierarchyId),
    ),
    R.map(R.assoc('filterHierarchyId', hierarchyId)),
    R.propOr([], 'previewClientSpendReportByHierarchy'),
  );

// getClientDataByProperty :: SpendReportQueryData -> { clientName: String, isTransparent: true }
const getClientDataByProperty = R.compose(
  R.applySpec({
    clientName: R.prop('name'),
    isTransparent: pathEqLegacy(
      ['financial', 'spendReportType'],
      ClientSpendReportTypes.TRANSPARENT,
    ),
  }),
  R.pathOr({}, [
    'previewClientSpendReportByHierarchy',
    '0',
    'properties',
    '0',
    'client',
  ]),
);

export const useSpendReportQuery = (filter, clientId) => {
  const skip = R.isEmpty(filter) || !filter?.startDate || !filter?.endDate;

  const additionalReportProps = {
    ...(!!filter && filter?.spendCostConfig
      ? { spendCostConfig: filter?.spendCostConfig }
      : {}),
  };

  const { data, loading } = useQuery(PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY, {
    variables: {
      input: { ...toMutationInput(filter), clientId },
    },
    skip: skip || !clientId,
    fetchPolicy: 'network-only',
  });

  const hierarchyId = filter ? filter.hierarchyId : null;
  const reports = prepareSpendReport(hierarchyId, additionalReportProps)(data);

  const { clientName, isTransparent } = getClientDataByProperty(data);

  return {
    reports,
    loading,
    clientId,
    clientName,
    isTransparent,
  };
};
