import React from 'react';
import { ForgotPasswordPageBase } from '@poly/common-ui';
import { useNavigate } from '@poly/client-routing';
import { routes } from '../routes.js';
import { APP_TITLE } from './LoginPage.js';

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const onSuccess = (response) =>
    navigate(`${routes.login}?forgotPasswordMessage=${response.message}`);
  const goToLogin = () => navigate(routes.login);

  return (
    <ForgotPasswordPageBase
      title={APP_TITLE}
      goToLogin={goToLogin}
      onSuccess={onSuccess}
    />
  );
}
