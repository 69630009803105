import { toast } from 'react-toastify';
import { getErrorMessagesByApolloError } from '@poly/apollo-client';
import { is2FASetupError } from '@poly/client-utils';
import { SETUP_2FA_REQUIRED_ERROR_MESSAGE } from '@poly/constants';

export const createHandleApolloErrorAtSiteUI = (logOutHandler) => (error) => {
  const errorsArr = getErrorMessagesByApolloError(error);

  if (is2FASetupError(errorsArr)) {
    logOutHandler();
    return toast.error(SETUP_2FA_REQUIRED_ERROR_MESSAGE);
  }

  return errorsArr.forEach(toast.error);
};
