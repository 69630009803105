import * as R from 'ramda';
import { formatFromToDateTitle } from '@poly/client-utils';
import {
  formatDate,
  ExcelExportCellType,
  createExcelExportCell,
} from '@poly/utils';

const prepareFilters = R.applySpec({
  projectId: R.pathOr('All', ['selectedProject', 'label']),
  requestor: R.pathOr('All', ['selectedRequestor', 'label']),
  startDate: R.compose(formatDate, R.prop('startDate')),
  endDate: R.compose(formatDate, R.prop('endDate')),
});

export const getConfirmSatisfactionReportXlsConfig = ({
  filters,
  projects,
}) => {
  const { projectId, requestor, startDate, endDate } = prepareFilters(filters);

  return {
    exportFileName: 'confirm-satisfaction-report.xlsx',
    columnWidths: [15, 70, 20, 15],
    rows: [
      [
        {
          value: 'Confirm Satisfaction Report',
          cellType: ExcelExportCellType.title,
        },
      ],
      [
        {
          value: `Project: ${projectId}`,
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      [
        {
          value: `Requestor: ${requestor}`,
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      [
        {
          value: formatFromToDateTitle(startDate, endDate),
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      R.zipWith(
        createExcelExportCell,
        R.repeat(ExcelExportCellType.tableHeader, 4),
        ['Project #', 'Project description', 'Requestor', 'Completion Date'],
      ),
      ...projects.map((project) =>
        R.zipWith(
          createExcelExportCell,
          R.repeat(ExcelExportCellType.default, 4),
          [
            R.prop('projectId', project),
            R.propOr('', 'description', project),
            R.pathOr('', ['contact', 'profile', 'fullName'], project),
            R.compose(formatDate, R.prop('workCompletionDate'))(project),
          ],
        ),
      ),
    ],
  };
};
