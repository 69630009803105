import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { ClientSpendReportTypes } from '@poly/constants';
import { pathEqLegacy } from '@poly/utils';

import {
  getOptionsValues,
  rejectAllOption,
} from '../SpendReportPage/helpers.js';
import { useCurrentClientConfig } from '../../hooks/useCurrentClientConfig.js';
import { prepareAssetReactiveSpendReport } from './prepareAssetReactiveSpendReport.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

const assetReactiveSpendReportQuery = gql`
  query assetReactiveSpendReportQuery($input: AssetReactiveSpendReportInput) {
    assetReactiveSpendReport(input: $input) {
      _id
      assetTypeName
      properties {
        propertyId
        propertyName
        assetsReport {
          assetId
          projectNumber
          workCompletionDate
          clientInvoicesDate
          projectDescription
          supplierInvoiceAmount
          clientInvoiceAmount
          assetDisplayName
        }
      }
    }
  }
`;

// getOptionsValuesByProp :: String -> Filter -> [String]
const getOptionsValuesByProp = (propName) =>
  R.compose(getOptionsValues, rejectAllOption, R.propOr([], propName));

// getAssetReactiveQueryInput :: Filter -> AssetReactiveSpendReportInput
const getAssetReactiveQueryInput = R.applySpec({
  propertyIds: getOptionsValuesByProp('properties'),
  assetTypesIds: getOptionsValuesByProp('assetTypes'),
  startDate: R.prop('startDate'),
  endDate: R.prop('endDate'),
});

export const useAssetReactiveSpendReport = (filter, isReportEnabled) => {
  const clientId = useCurrentClientId();
  const { data, loading } = useQuery(assetReactiveSpendReportQuery, {
    variables: {
      input: { ...getAssetReactiveQueryInput(filter), clientId },
    },
    skip: R.isEmpty(filter) || !clientId || !isReportEnabled,
    fetchPolicy: 'network-only',
  });

  const client = useCurrentClientConfig();

  const isTransparent = pathEqLegacy(
    ['financial', 'spendReportType'],
    ClientSpendReportTypes.TRANSPARENT,
    client,
  );

  return {
    report: prepareAssetReactiveSpendReport(data),
    loading,
    isTransparent,
  };
};
