import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { propertyStatuses } from '@poly/constants';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

const propertiesQuery = gql`
  query propertiesQuery($input: CollectionSearchParams) {
    searchProperties(input: $input) {
      total
      hits {
        _id
        status
        name
        masterProperty {
          _id
          name
        }
        address {
          formatted_address
        }
      }
    }
  }
`;

// getPropertiesQueryByStatusMap :: (String, String) -> ElasticQuery
const getPropertiesQueryByStatusMap = (status, clientId) => {
  const clientSearchQuery = clientId
    ? [
        {
          match: { clientId },
        },
      ]
    : [];
  const map = {
    [propertyStatuses.ACTIVE]: {
      bool: {
        must: [
          { match: { status: propertyStatuses.ACTIVE } },
          ...clientSearchQuery,
        ],
      },
    },
    [propertyStatuses.INACTIVE]: {
      bool: {
        must: [
          { match: { status: propertyStatuses.INACTIVE } },
          ...clientSearchQuery,
        ],
      },
    },
  };

  return map[status];
};

export const usePropertiesQuery = (status, sort) => {
  const searchText = useSelector(R.prop('searchText'));
  const { currentPage, size } = useSelector(R.prop('paginator'));

  const clientId = useCurrentClientId();

  const { data, loading } = useQuery(propertiesQuery, {
    variables: {
      input: {
        searchTerm: searchText,
        query: getPropertiesQueryByStatusMap(status, clientId),
        from: (currentPage - 1) * size,
        size,
        sort,
      },
    },
    skip: !clientId,
  });

  return {
    loading,
    properties: R.pathOr([], ['searchProperties', 'hits'], data),
    total: R.pathOr(0, ['searchProperties', 'total'], data),
  };
};
