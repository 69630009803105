import React from 'react';
import * as R from 'ramda';
import { projectStatusUITransform, SearchGrid } from '@poly/site-ui';
import { object, string, arrayOf } from 'prop-types';

import {
  projectsDescriptionFormatter,
  projectFormatter,
} from '../formaters.js';

const highlightProject =
  (search) =>
  ({ projectId }) =>
    projectFormatter(projectId, search);

const highlightProjectStatus = (search) => (project) =>
  projectStatusUITransform({ ...project, search });

const columnConfig = (search) => [
  [R.identity, highlightProject(search)],
  [R.identity, projectsDescriptionFormatter(search)],
  [R.identity, highlightProjectStatus(search)],
];

const gridColumns = `
  minmax(80px, 110px)
  auto
  minmax(80px, 130px)
`;

export function ProjectsContainer({ hits, searchTerm }) {
  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

ProjectsContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hits: arrayOf(object),
  searchTerm: string,
};
