import * as R from 'ramda';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { gql, useQuery, useApolloClient } from '@apollo/client';
import { useOutSidebarContext, useUserLogout } from '@poly/client-utils';
import { GlobalAddButton, GlobalSearch, UserMenu } from '@poly/site-ui';
import { CREATE_PROJECT_PERMISSION } from '@poly/security';
import { useLocation, Link } from '@poly/client-routing';
import { Nav, Header, S } from '@poly/site-book';
import { isNilOrEmpty, pathEqLegacy } from '@poly/utils';

import {
  AddProjectForm,
  addProjectFormId,
} from './ProjectsPage/AddProjectForm.js';
import { GlobalSearchDataContainer } from '../components/global-search/GlobalSearchDataContainer.js';
import { routes } from '../routes.js';
import { CurrentGlobalClientSelect } from '../components/CurrentGlobalClientSelect.js';
import { useCurrentClientConfig } from '../hooks/useCurrentClientConfig.js';

const LinkWrapper = Nav.Item.withComponent(Link);

const Logo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  min-width: 400px;
  align-items: center;
  justify-content: space-around;
`;

const ItemWrapper = styled(Header.Item)`
  transition: 0.3s;
  width: 100%;
  overflow: ${({ over }) => over && 'hidden'};
  margin-right: ${({ trigger }) => trigger && '0 !important'};
`;

const UserWrapper = styled(ItemWrapper)`
  width: ${({ trigger }) => trigger && 0};
`;

// isActive :: (String, [String]) -> String
const isActive = R.curry((activeTab, tabsNames) =>
  R.compose(
    R.ifElse(R.identity, R.always('active'), R.always('')),
    R.includes(activeTab),
  )(tabsNames),
);

let timer;

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      _id
      lastName
      firstName
    }
  }
`;

const NavigationContainer = styled(Header.Main)`
  background: #0e285d;
`;

function LogoIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 104 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M103.684 104H51.6389V73.48H72.6077L72.7335 51.9554H103.684V104V104ZM61.1617 94.4772H94.1567V61.4782H72.9201L73.1108 74.0481H61.1617V94.4732V94.4772Z"
        fill="#00DEDC"
      />
      <path
        d="M52.0446 52.0446H0V0H52.0446V52.0446ZM9.52278 42.5218H42.5218V9.52278H9.52278V42.5218Z"
        fill="#5363A1"
      />
      <path
        d="M52.0446 25.9391V35.4619H68.1972V68.461H35.2022V52.0446H25.6794V77.9838H77.7241V25.9391H52.0446Z"
        fill="white"
      />
    </svg>
  );
}

function GlobalAddBtn() {
  const { openOutSidebar, closeAllOutSidebars } = useOutSidebarContext();
  const onAddProject = () => {
    openOutSidebar({
      id: addProjectFormId,
      content: <AddProjectForm />,
    });
  };
  return (
    <GlobalAddButton
      onClick={closeAllOutSidebars}
      permissions={[CREATE_PROJECT_PERMISSION]}
      items={[
        {
          title: 'Add Work Order',
          onClick: onAddProject,
          permissions: [CREATE_PROJECT_PERMISSION],
        },
      ]}
    />
  );
}

// checkIsAllowedProjectCreation :: User -> Boolean
const checkIsAllowedProjectCreation = R.compose(
  R.both(
    pathEqLegacy(['configs', 'clientPortalApp', 'allowProjectCreation'], true),
    R.pathSatisfies(R.complement(isNilOrEmpty), [
      'configs',
      'clientPortalApp',
      'projectServiceType',
      '_id',
    ]),
  ),
);

export function Navigation() {
  const client = useApolloClient();
  const { pathname } = useLocation();
  const logOutUser = useUserLogout();
  const [overTrigger, setOverTrigger] = useState(false);
  const { isSearchFocused } = useSelector(R.prop('globalSearch'));

  const { data } = useQuery(ME_QUERY);
  const me = R.propOr({}, 'me', data);
  const currentClient = useCurrentClientConfig();

  const isAllowedProjectCreation = checkIsAllowedProjectCreation(currentClient);

  useEffect(() => {
    clearTimeout(timer);
    if (isSearchFocused) {
      setOverTrigger(true);
    } else {
      timer = setTimeout(() => setOverTrigger(false), 300);
    }
  }, [isSearchFocused]);

  const onLogout = () =>
    logOutUser({
      client,
      localStorage,
      loginRoute: routes.login,
    });

  return (
    <NavigationContainer>
      <Header.Row>
        <Header.Block>
          <Header.Item>
            <Logo>
              <LogoIcon />
            </Logo>
          </Header.Item>
          <Header.Item>
            <Nav>
              <LinkWrapper
                href={routes.home}
                active={isActive(pathname, [routes.home])}
              >
                <S type="header">Work Orders</S>
              </LinkWrapper>
              <LinkWrapper
                href={routes.properties}
                active={isActive(pathname, [routes.properties])}
              >
                <S type="header">Properties</S>
              </LinkWrapper>
              <LinkWrapper
                href={routes.reports}
                active={isActive(pathname, [routes.reports])}
              >
                <S type="header">Reports</S>
              </LinkWrapper>
            </Nav>
          </Header.Item>
        </Header.Block>
        <Header.Block>
          <CurrentGlobalClientSelect />
          {isAllowedProjectCreation && <GlobalAddBtn />}
          <SearchWrapper>
            <ItemWrapper trigger={isSearchFocused} over={overTrigger}>
              <GlobalSearch onFocus={() => {}}>
                <GlobalSearchDataContainer />
              </GlobalSearch>
            </ItemWrapper>
            <UserWrapper trigger={isSearchFocused} over={overTrigger}>
              <UserMenu {...me} onLogoutClick={onLogout} onClick={() => {}} />
            </UserWrapper>
          </SearchWrapper>
        </Header.Block>
      </Header.Row>
    </NavigationContainer>
  );
}
