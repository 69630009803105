import React, { useState } from 'react';
import { Layout } from '@poly/site-book';
import styled from 'styled-components';

import { Navigation } from '../Navigation.js';
import { LayoutContent } from '../MainLayout.js';
import { ProjectsPageHeader } from './ProjectsPageHeader.js';
import { ProjectsTable } from './ProjectsTable.js';
import { useProjectsQuery } from './useProjectsQuery.js';
import { PROJECTS_FILTERS } from './helpers.js';
import { SpendTotalInfo } from './SpendTotal.js';
import { useCurrentClientSpendTotalQuery } from './useClientSpendTotalQuery.js';

const PageBodyContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
`;

export function MainPage() {
  useProjectsQuery(PROJECTS_FILTERS.reactive, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.recurring, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.pm, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.all, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.onHold, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.pastDue, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.completedPendingInvoice, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.confirmSatisfaction, 'network-only');

  const [status, setStatus] = useState(PROJECTS_FILTERS.reactive);

  const {
    lastMonthSpend,
    lastQuarterSpend,
    yearToDateSpend,
    completedProjectsLast30Days,
  } = useCurrentClientSpendTotalQuery();

  return (
    <>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <PageBodyContainer>
        <SpendTotalInfo
          lastMonthSpend={lastMonthSpend}
          lastQuarterSpend={lastQuarterSpend}
          yearToDateSpend={yearToDateSpend}
          completedProjectsLast30Days={completedProjectsLast30Days}
        />
        <ProjectsPageHeader onClick={setStatus} activeFilter={status} />
        <LayoutContent>
          <ProjectsTable status={status} />
        </LayoutContent>
      </PageBodyContainer>
    </>
  );
}
