import React from 'react';
import * as R from 'ramda';
import { string, shape } from 'prop-types';
import { AbsoluteLoader, Paginator, Table } from '@poly/site-ui';
import { ASC_SORT_ORDER } from '@poly/constants';
import { forceTitleCase } from '@poly/utils';
import {
  useHighlightMatchesBySearch,
  keywordSortQuery,
} from '@poly/client-utils';

import { PropertyLink } from '../../components/Links.js';
import { HighlightedCell } from '../ProjectsPage/ProjectsTable.js';
import { useClearTableSearch } from '../../hooks/useClearTableSearch.js';
import { useTableSorting } from '../../hooks/useTableSorting.js';
import { usePropertiesQuery } from './usePropertiesQuery.js';

function MasterPropertyLink({ masterProperty }) {
  return masterProperty ? <PropertyLink {...masterProperty} /> : null;
}

MasterPropertyLink.propTypes = {
  masterProperty: shape({ _id: string.isRequired, name: string.isRequired }),
};

const columns = [
  ['Property', R.identity, PropertyLink, keywordSortQuery(['name'])],
  ['Address', R.path(['address', 'formatted_address']), HighlightedCell],
  ['Master Property', R.identity, MasterPropertyLink],
  ['Status', R.prop('status'), forceTitleCase],
];

const useTableProps = (properties) => ({
  rows: properties,
  columns: R.map(R.nth(1), columns),
  headers: R.map(R.nth(0), columns),
  formats: R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), columns),
  valuesToSort: R.map(R.nth(3), columns),
  gridColumns: `
      minmax(100px, 300px)
      minmax(300px, 1fr)
      minmax(100px, 300px)
      90px
    `,
});

export function PropertiesTable({ status }) {
  useClearTableSearch();

  const { sort, ...tableSortingProps } = useTableSorting(
    1,
    ASC_SORT_ORDER,
    columns,
  );
  const { properties, total, loading } = usePropertiesQuery(status, sort);

  const { rows, ...tableProps } = useTableProps(properties);

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    ['name', ['address', 'formatted_address']],
    rows,
    true,
  );

  if (loading) {
    return <AbsoluteLoader />;
  }

  return (
    <>
      <Table {...tableProps} {...tableSortingProps} rows={highlightedRows} />
      <Paginator total={total} />
    </>
  );
}

PropertiesTable.propTypes = {
  status: string.isRequired,
};
