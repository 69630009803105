import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { ELASTIC_RESULT_WINDOW_MAX_SIZE } from '@poly/constants';

const CONFIRM_SATISFACTION_REPORT_QUERY = gql`
  query CONFIRM_SATISFACTION_REPORT_QUERY($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        projectId
        description
        workCompletionDate
        contact {
          _id
          profile {
            fullName
          }
        }
      }
    }
  }
`;

// prepareQueryByFilters :: ID -> Filters -> Query
const prepareQueryByFilters = (clientId) =>
  R.compose(
    R.assocPath(['bool', 'must'], R.__, {}),
    R.reject(R.isNil),
    R.juxt([
      R.always({ match: { hasUncompletedConfirmSatisfactionTask: true } }),
      R.always({ exists: { field: 'workCompletionDate' } }),
      R.always({ term: { clientId } }),
      R.ifElse(
        R.propSatisfies(R.isNil, 'selectedProject'),
        R.always(null),
        R.applySpec({ term: { _id: R.path(['selectedProject', 'value']) } }),
      ),
      R.ifElse(
        R.propSatisfies(R.isNil, 'selectedRequestor'),
        R.always(null),
        R.applySpec({
          term: { contactId: R.path(['selectedRequestor', 'value']) },
        }),
      ),
      R.ifElse(
        R.both(
          R.propSatisfies(R.isNil, 'startDate'),
          R.propSatisfies(R.isNil, 'endDate'),
        ),
        R.always(null),
        ({ startDate, endDate }) => ({
          range: {
            workCompletionDate: {
              ...(!startDate ? {} : { gte: startDate }),
              ...(!endDate ? {} : { lte: endDate }),
              format: 'strict_date_optional_time',
            },
          },
        }),
      ),
    ]),
  );

// getQueryProjects :: { searchProjects: { hits: [Project] } } -> [Project]
const getQueryProjects = R.pathOr([], ['searchProjects', 'hits']);

export const useConfirmSatisfactionReportQuery = (filters, clientId) => {
  const query = prepareQueryByFilters(clientId)(filters || {});

  const { data, loading } = useQuery(CONFIRM_SATISFACTION_REPORT_QUERY, {
    variables: { input: { query, size: ELASTIC_RESULT_WINDOW_MAX_SIZE } },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  const projects = getQueryProjects(data);

  return { projects, loading };
};
