import * as R from 'ramda';

import { routes } from '../../routes.js';

export const reportsConfig = [
  {
    title: 'Work Orders',
    links: [
      { name: 'Spend Report', href: routes.spendReport },
      {
        name: 'Asset Reactive Spend Report',
        href: routes.assetReactivitySpendReport,
        renderIf: R.path([
          'client',
          'configs',
          'clientPortalApp',
          'showPMsAndAssets',
        ]),
      },
      {
        name: 'Confirm Satisfaction Report',
        href: routes.confirmSatisfactionReport,
      },
    ],
  },
];
