import React from 'react';
import { string } from 'prop-types';
import { collectionNames } from '@poly/constants';
import { EntityFilesTab } from '../../components/EntityFilesTab.js';

export function PropertyFilesTab({ propertyId }) {
  return (
    <EntityFilesTab
      collection={collectionNames.properties}
      documentId={propertyId}
      withAddNewBtn={false}
    />
  );
}

PropertyFilesTab.propTypes = {
  propertyId: string.isRequired,
};
