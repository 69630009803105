import * as R from 'ramda';
import { Layout } from '@poly/site-book';
import React from 'react';
import { Loader, ImagesGallery, transformProjectEndDate } from '@poly/site-ui';
import { gql } from '@apollo/client';
import { useRouterParams } from '@poly/client-routing';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';
import {
  useHasUserAccessWithPermission,
  useReactiveQuery,
} from '@poly/client-utils';
import { READ_ASSET_PERMISSION } from '@poly/security';

import { Navigation } from '../Navigation.js';
import { activeProjectsTabsMap, projectTabs } from './ProjectTabs.js';
import { TabsWithSearchSidebar } from '../../components/Tabs.js';
import { DetailsHeader, HeaderContainer } from '../../components/Header.js';
import { ProjectDetailsSection } from './ProjectDetailsSection.js';
import { tabsNames } from '../../constants/tabs.js';

const projectDetailsQuery = gql`
  query projectDetailsQuery($projectId: ID) {
    project(projectId: $projectId) {
      _id
      projectId
      status
      description
      priority
      startDate
      endDate
      workCompletionDate
      serviceType {
        name
      }
      manager {
        _id
        fullName
      }
      contact {
        _id
        fullName
      }
      property {
        _id
        name
        isMaster
        address {
          address_parts {
            street_number
            route
            locality
            administrative_area_level_1
            postal_code
          }
        }
      }
      subProperties {
        _id
        name
      }
      suppliers {
        _id
        company {
          name
        }
        statusInProject
      }
      assetIds
      client {
        financial {
          spendReportType
        }
      }
      jobCost
      clientInvoices {
        _id
        number
        amount
        invoiceDate
        status
        paidAt
      }
    }
  }
`;

const projectChangedSubscription = gql`
  subscription projectChangedSubscription($input: ProjectChangedSubInput!) {
    projectChanged(input: $input) {
      id
      type
    }
  }
`;

const BodyContent = styled(Layout.Content)`
  grid-template-columns: 28% 1fr;
  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
`;

// prepareTabProps :: (Boolean, TabProps) -> TabProps
// TabProps = {
//  tabs: [Tab],
//  activeTabsMap: Object,
// };
// Tab = {
//  title: String,
//  value: String,
// };
export const prepareTabProps = (hideAssetTab, tabProps) =>
  R.when(
    R.always(hideAssetTab),
    R.applySpec({
      tabs: R.compose(
        R.reject(
          R.propSatisfies(
            R.includes(R.__, [tabsNames.assets, tabsNames.pms]),
            'value',
          ),
        ),
        R.prop('tabs'),
      ),
      activeTabsMap: R.compose(
        R.dissoc(tabsNames.pms),
        R.dissoc(tabsNames.assets),
        R.prop('activeTabsMap'),
      ),
    }),
  )(tabProps);

const useProjectDetailsQuery = (projectId) => {
  const { data, loading } = useReactiveQuery(
    projectDetailsQuery,
    projectChangedSubscription,
    {
      queryOptions: {
        variables: { projectId },
        fetchPolicy: 'network-only',
        skip: !projectId,
      },
      subscriptionOptions: {
        variables: { input: { projectId } },
        skip: !projectId,
      },
    },
  );

  const project = R.propOr({}, 'project', data);
  return { project, loading };
};

export function ProjectDetailsPage() {
  const routerParams = useRouterParams(['projectId']);
  const { project, loading } = useProjectDetailsQuery(routerParams.projectId);
  const isAuthorizedToReadAssets = useHasUserAccessWithPermission(
    READ_ASSET_PERMISSION,
  );
  const hideAssetsTab =
    !isAuthorizedToReadAssets || isNilOrEmpty(project.assetIds);
  const { ...tabsProps } = prepareTabProps(hideAssetsTab, {
    tabs: projectTabs,
    activeTabsMap: activeProjectsTabsMap,
  });

  return (
    <Layout>
      <HeaderContainer>
        <Navigation />
        <DetailsHeader
          title={project.projectId}
          subtitle="Work Order"
          label={transformProjectEndDate(project)}
          loading={loading}
        />
      </HeaderContainer>
      <BodyContent>
        {loading ? <Loader /> : <ProjectDetailsSection project={project} />}
        <TabsWithSearchSidebar
          defaultTab={tabsNames.updates}
          projectDocumentId={project._id}
          projectId={project.projectId}
          assetIds={project.assetIds}
          loading={loading}
          {...tabsProps}
        />
      </BodyContent>
      <ImagesGallery />
    </Layout>
  );
}
