import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon } from '@poly/site-book';
import { func, string } from 'prop-types';

import { textColor } from '../pages/ProjectsPage/DetailsSection.js';
import { ranges } from '../constants/common.js';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
`;

const Title = styled.header`
  font-size: 16px;
  color: ${textColor};
`;

const OptionContainer = styled.span`
  color: #333;
  font-size: 12px;
  padding: 5px 10px;
  border-bottom: 1px solid #f6f9fd;
  background-color: #fff;
  :hover {
    background-color: #f6f9fd;
  }
`;

const SelectorContainer = styled.div`
  width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 2;
  position: absolute;
  top: 20px;
  width: 110px;
  z-index: 2;
  padding: 5px 0;
`;

const SelectedOption = styled.span`
  color: #333333;
  font-size: 12px;
  padding: 5px 10px;
`;

const rangesOptionsTitles = {
  [ranges.days30]: 'Last 30 days',
  [ranges.days60]: 'Last 60 days',
  [ranges.days90]: 'Last 90 days',
  [ranges.yearToDate]: 'Year to Date',
  [ranges.allTime]: 'All Time',
};

// getSelectOptions :: SelectedRange -> [String]
// SelectedRange = String
const getSelectOptions = (selectedRange) =>
  R.reject(R.equals(selectedRange), R.values(ranges));

function DateRangeSelector({ selectedRange, setSelectedRange }) {
  const [isOpened, setIsOpened] = useState(false);
  const options = getSelectOptions(selectedRange);
  const onClick = () => setIsOpened(!isOpened);
  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpened(false)}>
      <SelectorContainer onClick={onClick}>
        <SelectedOption>{rangesOptionsTitles[selectedRange]}</SelectedOption>
        {isOpened && (
          <OptionsContainer>
            {options.map((option) => (
              <OptionContainer
                onClick={() => setSelectedRange(option)}
                key={option}
              >
                {rangesOptionsTitles[option]}
              </OptionContainer>
            ))}
          </OptionsContainer>
        )}
        <Icon
          name={isOpened ? 'hierarchy-arrow-up' : 'hierarchy-arrow-down'}
          dimensions={{ width: 12, height: 12 }}
        />
      </SelectorContainer>
    </OutsideClickHandler>
  );
}

DateRangeSelector.propTypes = {
  selectedRange: string,
  setSelectedRange: func.isRequired,
};

DateRangeSelector.defaultProps = {
  selectedRange: ranges.days30,
};

export function OverviewDateSelector({ selectedRange, setSelectedRange }) {
  return (
    <Container>
      <Title>Overview</Title>
      <DateRangeSelector
        selectedRange={selectedRange}
        setSelectedRange={setSelectedRange}
      />
    </Container>
  );
}

OverviewDateSelector.propTypes = {
  selectedRange: string.isRequired,
  setSelectedRange: func.isRequired,
};
