import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Layout, Header, HeadingH1, S } from '@poly/site-book';
import { ALERTS, PrintAndExportButtons } from '@poly/site-ui';
import { endOfDay, startOfDay, startOfYear } from 'date-fns';
import { alwaysNewDate, isNilOrEmpty, toDate } from '@poly/utils';
import {
  func,
  shape,
  arrayOf,
  string,
  oneOfType,
  instanceOf,
} from 'prop-types';
import {
  performExcelExport,
  generateFileNameByTitle,
  openPrintWindowWithData,
} from '@poly/client-utils';

import { Navigation } from '../Navigation.js';
import { PrintLayout } from '../../components/print-visible-table.js';
import { ProjectSelect } from '../../components/ProjectSelect.js';
import { RequestorSelect } from '../../components/RequestorSelect.js';
import { projectPropTypes } from './prop-types.js';
import { ConfirmSatisfactionReportPageTable } from './ConfirmSatisfactionReportPageTable.js';
import { getConfirmSatisfactionReportXlsConfig } from './getConfirmSatisfactionReportXlsConfig.js';
import {
  ButtonS,
  DateRangePikerS,
  ExportButtonComponent,
} from '../SpendReportPage/SpendReportHeader.js';

const PAGE_TITLE = 'Confirm Satisfaction Report';

const HeaderBlockS = styled(Header.Block)`
  gap: 40px;
`;

const getDefaultFilters = () => {
  const now = alwaysNewDate();

  return {
    selectedProject: null,
    selectedRequestor: null,
    startDate: startOfYear(now),
    endDate: endOfDay(now),
  };
};

export function ConfirmSatisfactionReportPageHeader({
  filters,
  projects,
  clientId,
  setFilters,
}) {
  const [filterValues, setFilterValues] = useState(getDefaultFilters());

  const onChangeProject = (selectedProject) =>
    setFilterValues({ ...filterValues, selectedProject });

  const onChangeRequestor = (selectedRequestor) =>
    setFilterValues({ ...filterValues, selectedRequestor });

  const onChangeDateRange = ({ startDate, endDate }) =>
    setFilterValues({
      ...filterValues,
      startDate: startDate ? startOfDay(toDate(startDate)) : null,
      endDate: endDate ? endOfDay(toDate(endDate)) : null,
    });

  const printSpendReport = async () => {
    if (isNilOrEmpty(projects)) {
      toast.error(ALERTS.NoDataToDisplay);
      return Promise.resolve(null);
    }

    return openPrintWindowWithData({
      projects,
      fileName: generateFileNameByTitle(PAGE_TITLE),
      Table: ConfirmSatisfactionReportPageTable,
      metaData: { title: PAGE_TITLE },
      Layout: PrintLayout,
      skipFirefoxStyles: true,
    });
  };

  const exportSpendReport = () => {
    if (isNilOrEmpty(projects)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const config = getConfirmSatisfactionReportXlsConfig({
      filters,
      projects,
    });

    performExcelExport(config);
  };

  const onReset = () => {
    setFilters(null);
    setFilterValues(getDefaultFilters());
  };

  return (
    <Layout.Header>
      <Navigation />
      <Header.Sub>
        <Header.Row>
          <Header.Block>
            <HeadingH1 lighter>{PAGE_TITLE}</HeadingH1>
          </Header.Block>
          <Header.Block>
            <PrintAndExportButtons
              printTitle="Confirm Satisfaction Report"
              exportFunction={exportSpendReport}
              printFunction={() => printSpendReport}
              ExportButtonComponent={ExportButtonComponent}
            />
          </Header.Block>
        </Header.Row>
        <Header.Row>
          <HeaderBlockS>
            <ProjectSelect
              value={filterValues.selectedProject}
              clientId={clientId}
              onChange={onChangeProject}
            />
            <RequestorSelect
              value={filterValues.selectedRequestor}
              onChange={onChangeRequestor}
              placeholder="Start typing requestor"
              noResultsText="No Requestors Found"
            />
            <DateRangePikerS
              isLabel={false}
              startDate={filterValues.startDate}
              endDate={filterValues.endDate}
              onChange={onChangeDateRange}
            />
          </HeaderBlockS>
          <Header.Block>
            <ButtonS onClick={onReset}>
              <S type="title">Reset</S>
            </ButtonS>
            <ButtonS onClick={() => setFilters(filterValues)} mode="orange">
              <S type="title">Preview</S>
            </ButtonS>
          </Header.Block>
        </Header.Row>
      </Header.Sub>
    </Layout.Header>
  );
}

ConfirmSatisfactionReportPageHeader.propTypes = {
  clientId: string,
  setFilters: func.isRequired,
  projects: arrayOf(projectPropTypes),
  filters: shape({
    selectedProject: shape({ label: string, value: string }),
    selectedRequestor: shape({ label: string, value: string }),
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
  }),
};
