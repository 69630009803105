import * as R from 'ramda';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { debounce } from '@poly/utils';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from '@poly/client-utils';

import { BaseSelectComponent } from '../pages/SpendReportPage/PropertySelect.js';

const SelectWrapper = styled.div`
  width: 200px;
  position: relative;
`;

const SEARCH_PROJECTS_QUERY = gql`
  query SEARCH_PROJECTS_QUERY($searchInput: CollectionSearchParams!) {
    searchProjects(input: $searchInput) {
      hits {
        _id
        projectId
      }
    }
  }
`;

// getProjectSelectOptions :: { searchProjects: { hits: [Project] } } -> SelectOptions
const getProjectSelectOptions = R.compose(
  R.map(entityToOptionByLabelPath(['projectId'])),
  R.pathOr([], ['searchProjects', 'hits']),
);

export function ProjectSelect({ clientId, ...props }) {
  const selectRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(null);

  const setSearchTermDebounced = useCallback(debounce(500)(setSearchTerm), []);

  const searchInput = useMemo(
    () => ({
      searchTerm,
      ...(!clientId ? {} : { query: { term: { clientId } } }),
    }),
    [searchTerm, clientId],
  );

  const { data, loading } = useQuery(SEARCH_PROJECTS_QUERY, {
    variables: { searchInput },
    fetchPolicy: 'network-only',
    skip: !searchTerm,
  });

  const selectProps = {
    ...props,
    ref: selectRef,
    options: getProjectSelectOptions(data),
    isLoading: loading,
    isClearable: true,
    placeholder: 'Start typing projects',
    noResultsText: 'No Projects Found',
    onInputChange: setSearchTermDebounced,
    closeMenuOnSelect: true,
    blurInputOnSelect: true,
    onSelectResetsInput: false,
  };

  return (
    <SelectWrapper>
      <BaseSelectComponent {...selectProps} />
    </SelectWrapper>
  );
}

ProjectSelect.propTypes = {
  clientId: string,
};
