import * as R from 'ramda';
import { assocBy, calculateTotal } from '@poly/utils';

// prepareAssetReactiveSpendReport :: AssetReactiveSpendReportQueryData -> [AssetReactiveSpendReport]
export const prepareAssetReactiveSpendReport = R.compose(
  R.applySpec({
    reports: R.identity,
    total: calculateTotal(R.propOr(0, 'total')),
  }),
  R.map(
    R.compose(
      assocBy(
        'total',
        R.compose(
          calculateTotal(R.propOr(0, 'total')),
          R.propOr([], 'properties'),
        ),
      ),
      assocBy(
        'properties',
        R.compose(
          R.map(
            assocBy(
              'total',
              R.compose(
                calculateTotal(R.propOr(0, 'clientInvoiceAmount')),
                R.prop('assetsReport'),
              ),
            ),
          ),
          R.propOr([], 'properties'),
        ),
      ),
    ),
  ),
  R.propOr([], 'assetReactiveSpendReport'),
);
