import React, { useState } from 'react';
import { propertyStatuses } from '@poly/constants';
import { Layout } from '@poly/site-book';

import { Navigation } from '../Navigation.js';
import { LayoutContent } from '../MainLayout.js';
import { PropertiesTable } from './PropertiesTable.js';
import { PropertiesPageHeader } from './PropertiesPageHeader.js';

export function PropertiesPage() {
  const [status, setStatus] = useState(propertyStatuses.ACTIVE);

  return (
    <>
      <Layout.Header>
        <Navigation />
        <PropertiesPageHeader activeFilter={status} onClick={setStatus} />
      </Layout.Header>
      <LayoutContent>
        <PropertiesTable status={status} />
      </LayoutContent>
    </>
  );
}
