import React from 'react';
import { gql } from '@apollo/client';
import * as R from 'ramda';
import { usePaginatorState } from '@poly/site-ui';
import { useSelector } from 'react-redux';
import {
  keywordNestedSortQuery,
  keywordSortQuery,
  paginationToQueryParams,
  pathOrNothingUI,
  useReactiveQuery,
} from '@poly/client-utils';
import {
  ASC_SORT_ORDER,
  NOTHING_UI_STRING,
  RecurringProjectTypes,
} from '@poly/constants';
import { string } from 'prop-types';
import { propEqLegacy } from '@poly/utils';

import { TableWithPagination } from '../../components/TableWithPaginationContainer.js';
import { AssetLink } from '../../components/Links.js';
import { useTablePropsWithHighLight } from '../../hooks/useTablePropsWithHighlight.js';
import { useTableSorting } from '../../hooks/useTableSorting.js';

const propertyAssetsQuery = gql`
  query propertyAssetsQuery($searchInput: CollectionSearchParams!) {
    searchAssets(input: $searchInput) {
      hits {
        _id
        displayName
        manufacturerDoc {
          _id
          name
        }
        modelDoc {
          _id
          name
        }
        type {
          _id
          name
        }
        qrCodeId
        suppliers {
          _id
          company {
            name
          }
        }
        recurringProjects {
          _id
          projectId
          type
        }
      }
      total
    }
  }
`;

const getAssetsResult = R.pathOr([], ['searchAssets', 'hits']);
const getAssetsTotalResult = R.pathOr(0, ['searchAssets', 'total']);

const assetsSubscription = gql`
  subscription SEARCH_ASSETS_SUB($searchInput: CollectionSearchParams!) {
    searchAssetChanged(input: $searchInput) {
      id
      type
    }
  }
`;

export const usePropertyAssetsQuery = (propertyId, sort, skip) => {
  const { paginator } = usePaginatorState();
  const searchText = useSelector(R.prop('searchText'));
  const { size, currentPage } = paginator;
  const searchInput = {
    searchTerm: searchText,
    query: {
      term: { propertyId },
    },
    ...paginationToQueryParams({
      itemsPerPage: size,
      currentPage,
    }),
    ...(sort ? { sort } : {}),
  };
  const { data, loading } = useReactiveQuery(
    propertyAssetsQuery,
    assetsSubscription,
    {
      queryOptions: {
        variables: {
          searchInput,
        },
        fetchPolicy: 'network-only',
        skip,
      },
      subscriptionOptions: { variables: { searchInput } },
    },
  );
  return {
    assets: getAssetsResult(data),
    total: getAssetsTotalResult(data),
    loading,
  };
};

// formatAssetSuppliers :: Asset -> String
const formatAssetSuppliers = R.compose(
  R.ifElse(R.isEmpty, R.always(NOTHING_UI_STRING), R.join('\n')),
  R.map(R.path(['company', 'name'])),
  R.propOr([], 'suppliers'),
);

// formatAssetPMsColumns :: [RecurringProject] -> String
const formatAssetPMsColumn = R.compose(
  R.join(', '),
  R.map(R.prop('projectId')),
  R.filter(
    propEqLegacy('type', RecurringProjectTypes.preventiveMaintenanceProject),
  ),
);

const assetsTableConfig = [
  [
    'Asset ID',
    R.pick(['_id', 'displayName']),
    AssetLink,
    keywordSortQuery(['displayName']),
  ],
  [
    'Manufacturer',
    pathOrNothingUI(['manufacturerDoc', 'name']),
    R.identity,
    keywordSortQuery(['manufacturerDoc', 'name']),
  ],
  [
    'Model Number',
    pathOrNothingUI(['modelDoc', 'name']),
    R.identity,
    keywordSortQuery(['modelDoc', 'name']),
  ],
  [
    'Suppliers',
    formatAssetSuppliers,
    R.identity,
    keywordNestedSortQuery(['suppliers', 'company', 'name']),
  ],
  [
    'Type',
    pathOrNothingUI(['type', 'name']),
    R.identity,
    keywordSortQuery(['type', 'name']),
    keywordNestedSortQuery(['suppliers', 'company', 'name']),
  ],
  [
    'PMs',
    R.pathOr([], ['recurringProjects']),
    formatAssetPMsColumn,
    keywordNestedSortQuery(['recurringProjects', 'projectId']),
  ],
];

export function PropertyAssetsTab({ propertyId }) {
  const { sort, ...tableSortingProps } = useTableSorting(
    1,
    ASC_SORT_ORDER,
    assetsTableConfig,
  );
  const { assets, total, loading } = usePropertyAssetsQuery(propertyId, sort);
  const tableProps = useTablePropsWithHighLight(assets, assetsTableConfig, [
    'qrCodeId',
    ['manufacturerDoc', 'name'],
    ['modelDoc', 'name'],
    ['type', 'name'],
    'displayName',
  ]);

  return (
    <TableWithPagination
      total={total}
      loading={loading}
      entity="Assets"
      gridColumns="
            minmax(100px, 150px) 
            minmax(100px, 150px)
            minmax(100px, 150px) 
            minmax(105px, 250px) 
            minmax(150px, 200px) 
            minmax(100px, 300px)
          "
      {...tableProps}
      {...tableSortingProps}
    />
  );
}

PropertyAssetsTab.propTypes = {
  propertyId: string.isRequired,
};
