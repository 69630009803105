import { AssetStatuses } from '@poly/constants';

export const AssetStatusColors = {
  [AssetStatuses.ACTIVE]: '#85BB49',
  [AssetStatuses.INACTIVE]: '#9ea2a9',
};

export const AssetStatusUI = {
  [AssetStatuses.ACTIVE]: 'Active',
  [AssetStatuses.INACTIVE]: 'Inactive',
};
