import * as R from 'ramda';
import { mapConfigTableProps } from '@poly/site-ui';
import { useHighlightMatchesBySearch } from '@poly/client-utils';

export const useTablePropsWithHighLight = (
  documents,
  tableConfig,
  highlightConfig,
) => {
  const { rows, ...tableProps } = mapConfigTableProps(documents, tableConfig);
  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    highlightConfig,
    rows,
    true,
  );
  return {
    ...tableProps,
    rows: highlightedRows,
  };
};
