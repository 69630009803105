import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@poly/site-book';
import { arrayOf } from 'prop-types';
import { NOTHING_UI_STRING } from '@poly/constants';
import { formatDate, isNilOrEmpty } from '@poly/utils';

import { ProjectLink } from '../../components/ProjectLink.js';
import { projectPropTypes } from './prop-types.js';
import {
  GridRow,
  TextCenter,
  HeaderCell,
  GridScrollBody,
} from '../SpendReportPage/components.js';

const ConfirmSatisfactionReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridScrollBodyS = styled(GridScrollBody)`
  height: calc(100vh - 265px);
`;

const confirmSatisfactionReportTableConfig = [
  [
    'Project #',
    ({ projectId }) => <ProjectLink projectId={projectId} target="_blank" />,
  ],
  ['Project description', R.propOr(NOTHING_UI_STRING, 'description')],
  [
    'Requestor',
    R.pathOr(NOTHING_UI_STRING, ['contact', 'profile', 'fullName']),
  ],
  ['Completion Date', R.compose(formatDate, R.prop('workCompletionDate'))],
];

export function ConfirmSatisfactionReportPageTable({ projects }) {
  const columns = R.map(R.nth(1), confirmSatisfactionReportTableConfig);
  const headers = R.map(R.nth(0), confirmSatisfactionReportTableConfig);
  const gridColumns = '100px calc(100% - 480px) 200px 150px';

  if (isNilOrEmpty(projects)) {
    return <TextCenter>No data to report</TextCenter>;
  }

  return (
    <ConfirmSatisfactionReportWrapper>
      <Grid columns={gridColumns}>
        <Grid.Head>
          {headers.map((header) => (
            <Grid.Cell key={header}>
              <HeaderCell type="title">{header}</HeaderCell>
            </Grid.Cell>
          ))}
        </Grid.Head>
      </Grid>
      <Grid columns={gridColumns}>
        <GridScrollBodyS>
          {projects.map((project) => (
            <GridRow key={`row_${project?._id}`}>
              {columns.map((column, index) => (
                <Grid.Cell key={`cell_${project._id}_${headers[index]}`}>
                  {column(project)}
                </Grid.Cell>
              ))}
            </GridRow>
          ))}
        </GridScrollBodyS>
      </Grid>
    </ConfirmSatisfactionReportWrapper>
  );
}

ConfirmSatisfactionReportPageTable.propTypes = {
  projects: arrayOf(projectPropTypes),
};
