import React from 'react';
import styled from 'styled-components';
import { Icon } from '@poly/site-book';
import { useTableSearch, setCurrent } from '@poly/site-ui';

import { setSearchText } from '../redux/searchText.js';

const SearchInputC = styled.input`
  width: 180px;
  height: 20px;
  border: none;
  font-size: 12px;
  margin-left: 5px;
  padding-right: 20px;
  ::placeholder {
    color: #bcbcbc;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const ClearIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 15px;
`;

export function TabsSearchInput() {
  const { setRef, onClear, onChange, disabled, hideCloseIcon } = useTableSearch(
    {
      setTableSearchString: setSearchText,
      setPaginationCurrentPage: setCurrent,
    },
  );
  return (
    <SearchInputContainer>
      <Icon
        name="search"
        dimensions={{ width: 15, height: 15 }}
        fill="#BCBCBC"
      />
      <SearchInputC
        onChange={onChange}
        disabled={disabled}
        autoFocus
        ref={(ref) => setRef(ref)}
        placeholder="Search"
      />
      {!hideCloseIcon && (
        <ClearIconContainer onClick={onClear}>
          <Icon
            name="close"
            dimensions={{ width: 10, height: 10 }}
            fill="#9197AC"
          />
        </ClearIconContainer>
      )}
    </SearchInputContainer>
  );
}
