import * as R from 'ramda';
import { formatDate, forceTitleCase } from '@poly/utils';
import { object } from 'prop-types';
import { usePaginatorState } from '@poly/site-ui';
import { DESC_SORT_ORDER } from '@poly/constants';
import {
  commonSortQuery,
  keywordSortQuery,
  paginationToQueryParams,
  useReactiveQuery,
} from '@poly/client-utils';
import React from 'react';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';

import { TableWithPagination } from './TableWithPaginationContainer.js';
import { useTablePropsWithHighLight } from '../hooks/useTablePropsWithHighlight.js';
import { useTableSorting } from '../hooks/useTableSorting.js';

// getMasterProjectsFromResult :: Object -> [Project]
const getMasterProjectsFromResult = R.pathOr(
  [],
  ['searchRecurringProjects', 'hits'],
);

// getMasterProjectsTotalFromResult :: Object -> [Project]
const getMasterProjectsTotalFromResult = R.pathOr(0, [
  'searchRecurringProjects',
  'total',
]);

const recurringProjectsColumns = [
  ['WO #', R.prop('projectId'), R.identity, keywordSortQuery(['projectId'])],
  [
    'Description',
    R.prop('description'),
    R.identity,
    keywordSortQuery(['description']),
  ],
  [
    'Start Date',
    R.prop('startDate'),
    formatDate,
    commonSortQuery(['startDate']),
  ],
  ['End Date', R.prop('endDate'), formatDate, commonSortQuery(['endDate'])],
  ['Status', R.prop('status'), forceTitleCase, commonSortQuery(['status'])],
];

const masterProjectsQuery = gql`
  query masterProjectsQuery($input: CollectionSearchParams!) {
    searchRecurringProjects(input: $input) {
      hits {
        _id
        description
        startDate
        endDate
        projectId
        status
      }
      total
    }
  }
`;

const masterProjectsSubscription = gql`
  subscription masterProjectsSubscription($input: CollectionSearchParams!) {
    searchRecurringProjectChanged(input: $input) {
      id
      type
    }
  }
`;

export function EntityMasterProjectsTab({ searchQuery }) {
  const { paginator } = usePaginatorState();
  const searchText = useSelector(R.prop('searchText'));

  const { sort, ...tableSortingProps } = useTableSorting(
    3,
    DESC_SORT_ORDER,
    recurringProjectsColumns,
  );

  const { size, currentPage } = paginator;
  const searchInput = {
    query: searchQuery,
    searchTerm: searchText,
    ...paginationToQueryParams({ itemsPerPage: size, currentPage }),
    sort,
  };
  const { data, loading } = useReactiveQuery(
    masterProjectsQuery,
    masterProjectsSubscription,
    {
      queryOptions: {
        variables: {
          input: searchInput,
        },
      },
      subscriptionOptions: {
        variables: { input: searchInput },
      },
    },
  );

  const masterProjects = getMasterProjectsFromResult(data);
  const total = getMasterProjectsTotalFromResult(data);

  const tableProps = useTablePropsWithHighLight(
    masterProjects,
    recurringProjectsColumns,
    ['projectId', 'description'],
  );

  return (
    <TableWithPagination
      total={total}
      loading={loading}
      entity="Projects"
      gridColumns="
        minmax(75px, 150px)
        minmax(350px, 600px)
        repeat(2, minmax(75px, 100px))
        minmax(80px, 160px)
      "
      {...tableProps}
      {...tableSortingProps}
    />
  );
}

EntityMasterProjectsTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  searchQuery: object.isRequired,
};
