import React from 'react';
import * as R from 'ramda';
import { object, string, arrayOf } from 'prop-types';
import { SearchGrid } from '@poly/site-ui';

import {
  propertiesDescriptionFormatter,
  statusFormatter,
} from '../formaters.js';

const columnConfig = (search) => [
  [R.identity, propertiesDescriptionFormatter(search)],
  [R.prop('status'), statusFormatter(search)],
];

const gridColumns = `
  auto
  minmax(80px, 130px)
`;

export function PropertiesContainer({ hits, searchTerm }) {
  return (
    <SearchGrid
      rows={hits}
      columnConfig={columnConfig}
      searchTerm={searchTerm}
      gridColumns={gridColumns}
    />
  );
}

PropertiesContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  hits: arrayOf(object),
  searchTerm: string,
};
