import React from 'react';
import { Loader, Paginator, Table } from '@poly/site-ui';
import { bool, number, string } from 'prop-types';
import styled from 'styled-components';
import { EmptyListMsg } from './EmptyListMsg.js';

const TableWithPaginationContainer = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-rows: 1fr auto;
  padding-top: 25px;
`;

const PaginatorContainer = styled.div`
  display: grid;
  padding: 16px;
  justify-content: end;
`;

export function TableWithPagination({
  total,
  loading,
  gridColumns,
  entity,
  ...tableProps
}) {
  return (
    <TableWithPaginationContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          {total > 0 ? (
            <Table {...tableProps} gridColumns={gridColumns} />
          ) : (
            <EmptyListMsg entity={entity} />
          )}
          <PaginatorContainer>
            <Paginator total={total} />
          </PaginatorContainer>
        </>
      )}
    </TableWithPaginationContainer>
  );
}

TableWithPagination.propTypes = {
  total: number.isRequired,
  loading: bool,
  gridColumns: string.isRequired,
  entity: string.isRequired,
};
