import { propertyStatuses } from '@poly/constants';

export const propertyStatusesUIMap = {
  [propertyStatuses.ACTIVE]: 'Active',
  [propertyStatuses.INACTIVE]: 'Inactive',
};

export const propertyStatusesColorsMap = {
  [propertyStatuses.ACTIVE]: '#85BB49',
  [propertyStatuses.INACTIVE]: '#9ea2a9',
};
