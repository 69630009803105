import React from 'react';
import { Grid } from '@poly/site-book';
import { arrayOf, shape, string } from 'prop-types';

import { MoneyGridCell, HeaderCell } from './components.js';
import { spendReportCellType } from './constants.js';
import { spendReportTableHeaderPropTypes } from './prop-types.js';

export function SpendReportTableHeader({ gridColumns, headers }) {
  return (
    <Grid columns={gridColumns}>
      <Grid.Head>
        {headers.map(({ title, type }) =>
          type === spendReportCellType.money ? (
            <MoneyGridCell key={title}>
              <HeaderCell type="title">{title}</HeaderCell>
            </MoneyGridCell>
          ) : (
            <Grid.Cell key={title}>
              <HeaderCell type="title">{title}</HeaderCell>
            </Grid.Cell>
          ),
        )}
      </Grid.Head>
    </Grid>
  );
}

SpendReportTableHeader.propTypes = {
  gridColumns: string.isRequired,
  headers: arrayOf(shape(spendReportTableHeaderPropTypes)).isRequired,
};
