import { instanceOf, oneOfType, shape, string } from 'prop-types';

export const projectPropTypes = shape({
  _id: string,
  projectId: string,
  description: string,
  workCompletionDate: oneOfType([instanceOf(Date), string]),
  contact: shape({
    _id: string,
    profile: shape({
      fullName: string,
    }),
  }),
});
