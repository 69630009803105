import React, { useState } from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { useReactiveQuery } from '@poly/client-utils';
import { useRouterParams } from '@poly/client-routing';
import { ImagesGallery } from '@poly/site-ui';

import { LayoutContent } from '../MainLayout.js';
import { Navigation } from '../Navigation.js';
import { DetailsHeader, HeaderContainer } from '../../components/Header.js';
import { AssetDetailsSection } from './AssetDetailsSection.js';
import { AssetOverviewSection } from './AssetOverviewSection.js';
import { AssetTabs } from './AssetTabs.js';
import { EntityOverviewContainer } from '../../components/Layout.js';
import { ranges } from '../../constants/common.js';
import { OverviewDateSelector } from '../../components/OverviewDateSelector.js';

const assetDetailsFragment = gql`
  fragment assetDetailsFragment on Asset {
    _id
    displayName
    status
    description
    property {
      _id
      name
    }
    manufacturerDoc {
      _id
      name
    }
    modelDoc {
      _id
      name
    }
    type {
      _id
      name
    }
    qrCodeId
    serial
    location
    warrantyEnd
    purchasePrice
    commissioningDate
    photo {
      url
      fileName
    }
  }
`;

const assetDetailsQuery = gql`
  query assetDetailsQuery($assetId: ID!) {
    asset(id: $assetId) {
      _id
      ...assetDetailsFragment
    }
  }
  ${assetDetailsFragment}
`;

const assetDetailsSubscription = gql`
  subscription assetDetailsSubscription($input: SingleDocSubInput!) {
    assetChanged(input: $input) {
      id
      type
      document {
        ...assetDetailsFragment
      }
    }
  }
  ${assetDetailsFragment}
`;

const getAssetDocument = R.propOr({}, 'asset');

const useAssetDetailsQuery = (assetId) => {
  const { data, loading } = useReactiveQuery(
    assetDetailsQuery,
    assetDetailsSubscription,
    {
      queryOptions: {
        variables: {
          assetId,
        },
        skip: !assetId,
      },
      subscriptionOptions: {
        variables: { input: { id: assetId } },
        skip: !assetId,
      },
    },
  );
  return {
    asset: getAssetDocument(data),
    loading,
  };
};

export function AssetDetailsPage() {
  const { assetId } = useRouterParams(['assetId']);
  const [selectedRange, setSelectedRange] = useState(ranges.days30);
  const { asset, loading } = useAssetDetailsQuery(assetId);

  return (
    <>
      <HeaderContainer>
        <Navigation />

        <DetailsHeader
          title={asset.displayName}
          loading={loading}
          subtitle="Asset"
        />
      </HeaderContainer>
      <LayoutContent columns="450px 1fr">
        <AssetDetailsSection asset={asset} />
        <EntityOverviewContainer>
          <OverviewDateSelector
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <AssetOverviewSection
            assetId={assetId}
            selectedRange={selectedRange}
          />
          <AssetTabs assetId={assetId} loading={loading} />
        </EntityOverviewContainer>
        <ImagesGallery />
      </LayoutContent>
    </>
  );
}
