import React from 'react';
import { node } from 'prop-types';
import { AuthRoutesRedirect } from '@poly/client-utils';
import { routes } from '../routes.js';

export function LoginLayout({ children }) {
  return (
    <>
      {children}
      <AuthRoutesRedirect
        homeRoute={routes.home}
        authRoutesList={[
          routes.login,
          routes.forgotPassword,
          routes.changePassword,
        ]}
      />
    </>
  );
}

LoginLayout.propTypes = {
  children: node.isRequired,
};
