import { gql, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { endOfCurrentDay } from '@poly/utils';
import { WorkOrderStatus } from '@poly/constants/src/index.js';
import { getStartDateOfRange } from '../../constants/common.js';

const assetProjectsTotalQuery = gql`
  query assetProjectsTotalQuery(
    $assetId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    asset(id: $assetId) {
      _id
      searchProjects(input: $searchInput) {
        total
      }
    }
  }
`;

// getAssetProjectsTotal :: Object -> Number
const getAssetProjectsTotal = R.pathOr(0, ['asset', 'searchProjects', 'total']);

// getDateRangeQuery :: SelectedRange -> Object
const getDateRangeQuery = (selectedRange) => {
  const workCompletionDate = getStartDateOfRange(selectedRange);
  return {
    range: {
      workCompletionDate: {
        lte: endOfCurrentDay(),
        ...(workCompletionDate ? { gte: workCompletionDate } : {}),
      },
    },
  };
};

// getSearchQueryBase :: Object -> SelectedRange -> Object
const getSearchQueryBase = (baseQuery, selectedRange) =>
  R.compose(
    R.assocPath(['bool', 'must', 'match'], {
      status: WorkOrderStatus.COMPLETED,
    }),
    R.assocPath(['bool', 'filter'], R.__, baseQuery),
    getDateRangeQuery,
  )(selectedRange);

export const useProjectsTotalByDatePeriodQuery = (
  assetId,
  searchQuery,
  selectedRange,
) => {
  const { data, loading } = useQuery(assetProjectsTotalQuery, {
    variables: {
      assetId,
      searchInput: {
        query: getSearchQueryBase(searchQuery, selectedRange),
      },
    },
    fetchPolicy: 'network-only',
    skip: !assetId,
  });
  const total = getAssetProjectsTotal(data);
  return {
    total,
    loading,
  };
};
