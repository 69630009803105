import React, { useState } from 'react';
import { Loader } from '@poly/site-ui';

import { LayoutContent } from '../MainLayout.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';
import { useConfirmSatisfactionReportQuery } from './useConfirmSatisfactionReportQuery.js';
import { ConfirmSatisfactionReportPageTable } from './ConfirmSatisfactionReportPageTable.js';
import { ConfirmSatisfactionReportPageHeader } from './ConfirmSatisfactionReportPageHeader.js';

export function ConfirmSatisfactionReportPage() {
  const [filters, setFilters] = useState(null);

  const clientId = useCurrentClientId();

  const { projects, loading } = useConfirmSatisfactionReportQuery(
    filters,
    clientId,
  );

  return (
    <>
      <ConfirmSatisfactionReportPageHeader
        filters={filters}
        projects={projects}
        clientId={clientId}
        setFilters={setFilters}
      />
      <LayoutContent>
        {loading ? (
          <Loader absolute />
        ) : (
          <ConfirmSatisfactionReportPageTable projects={projects} />
        )}
      </LayoutContent>
    </>
  );
}
