import React from 'react';
import { ChangePasswordPageBase } from '@poly/common-ui';
import { useNavigate, useRouterParams } from '@poly/client-routing';
import { routes } from '../routes.js';
import { APP_TITLE } from './LoginPage.js';

export function ChangePasswordPage() {
  const { token } = useRouterParams(['token']);
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(routes.home);
  };

  const navigateToForgotPassword = () => navigate(routes.forgotPassword);

  return (
    <ChangePasswordPageBase
      token={token}
      onSuccess={onSuccess}
      title={APP_TITLE}
      navigateToForgotPassword={navigateToForgotPassword}
    />
  );
}
