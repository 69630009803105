import React from 'react';

import {
  BaseSelectComponent,
  SpendReportSelectWrapper,
} from './PropertySelect.js';

export function HierarchySelect(props) {
  return (
    <SpendReportSelectWrapper>
      <BaseSelectComponent
        {...props}
        clearable={false}
        placeholder="Start typing hierarchy"
        noResultsText="No Hierarchy Found"
        onSelectResetsInput={false}
      />
    </SpendReportSelectWrapper>
  );
}
