import { endOfCurrentDay, startOfCurrentYear } from '@poly/utils';

export const ranges = {
  days30: '30Days',
  days60: '60Days',
  days90: '90Days',
  yearToDate: 'yearToDate',
  allTime: 'allTime',
};

// startOfLastNDays :: Number -> Date
const startOfLastNDays = (numberOfDays) => {
  const date = new Date();
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - numberOfDays,
  );
};

// getStartDateOfRange :: SelectedRange -> Date
export const getStartDateOfRange = (range) => {
  if (range === ranges.days30) {
    return startOfLastNDays(30);
  }
  if (range === ranges.days60) {
    return startOfLastNDays(60);
  }
  if (range === ranges.days90) {
    return startOfLastNDays(90);
  }
  if (range === ranges.yearToDate) {
    return startOfCurrentYear();
  }
  return null;
};

export const getRangeDates = (selectedRange) => {
  const dates = {
    endDate: endOfCurrentDay(),
  };
  if (selectedRange !== ranges.allTime) {
    dates.startDate = getStartDateOfRange(selectedRange);
  }
  return dates;
};
