import React from 'react';
import { ProjectSpendTypes } from '@poly/constants';

import {
  BaseSelectComponent,
  SpendReportSelectWrapper,
} from './PropertySelect.js';

export function ProjectSpendTypeSelect(props) {
  const options = [
    { value: ProjectSpendTypes.REACTIVE_REPAIR, label: 'Reactive spend' },
    { value: ProjectSpendTypes.RECURRING_SERVICE, label: 'Recurring spend' },
    { value: ProjectSpendTypes.CAP_EX, label: 'Capital spend' },
  ];

  return (
    <SpendReportSelectWrapper>
      <BaseSelectComponent
        {...props}
        isClearable
        options={options}
        closeMenuOnSelect
        placeholder="Select spend type"
      />
    </SpendReportSelectWrapper>
  );
}
