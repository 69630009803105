import * as R from 'ramda';
import { formatFromToDateTitle } from '@poly/client-utils';
import {
  ExcelExportCellType,
  convertCentsToDollars,
  createExcelExportCell,
  formatDate,
} from '@poly/utils';

const COLUMN_WIDTH = [20, 20, 15, 15, 50, 20, 30, 15];

// omitNotTransparentColumns :: Boolean -> [ExcelExportDataCell] -> [ExcelExportDataCell]
const omitNotTransparentColumns = R.curry((isTransparent, cells) =>
  R.unless(R.always(isTransparent), R.compose(R.values, R.omit([7])))(cells),
);

// getRowsByAssetsReport :: AssetReactiveSpendReport -> [[String]]
const getRowsByAssetsReport = ({ assetTypeName, propertyName, assetsReport }) =>
  R.map(
    R.juxt([
      R.prop('projectNumber'),
      R.prop('assetDisplayName'),
      R.compose(formatDate, R.prop('workCompletionDate')),
      R.compose(formatDate, R.prop('clientInvoicesDate')),
      R.prop('projectDescription'),
      R.always(assetTypeName),
      R.always(propertyName),
      R.compose(convertCentsToDollars, R.propOr(0, 'supplierInvoiceAmount')),
      R.compose(convertCentsToDollars, R.propOr(0, 'clientInvoiceAmount')),
    ]),
    assetsReport,
  );

// getRowsByPropertyConfig :: AssetReactiveSpendReportResult -> [String]
const getRowsByPropertyConfig = ({ assetTypeName, properties }) =>
  R.compose(
    R.unnest,
    R.map(
      R.compose(
        getRowsByAssetsReport,
        R.mergeRight({ assetTypeName }),
        R.pick(['propertyName', 'assetsReport']),
      ),
    ),
  )(properties);

// getAssetReactiveSpendReportsRows :: Boolean -> [AssetReactiveSpendReportResult] -> [ExcelExportDataCell]
const getAssetReactiveSpendReportsRows = (isTransparent, reports) =>
  R.compose(
    R.map(
      R.compose(
        omitNotTransparentColumns(isTransparent),
        R.zipWith(createExcelExportCell, [
          ...R.repeat(ExcelExportCellType.default, 7),
          ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
        ]),
      ),
    ),
    R.unnest,
    R.map(getRowsByPropertyConfig),
  )(reports);

export const getAssetReactiveSpendReportsXlsConfig = ({
  startDate,
  endDate,
  isTransparent,
  report,
}) => {
  const columnWidths = isTransparent ? [...COLUMN_WIDTH, 15] : COLUMN_WIDTH;

  return {
    exportFileName: 'asset-reactive-spend-report.xlsx',
    columnWidths,
    rows: [
      [
        {
          value: 'Asset Reactive Spend Report',
          cellType: ExcelExportCellType.title,
        },
      ],
      [
        {
          value: formatFromToDateTitle(startDate, endDate),
          cellType: ExcelExportCellType.subTitle,
        },
      ],
      omitNotTransparentColumns(
        isTransparent,
        R.zipWith(
          createExcelExportCell,
          [
            ...R.repeat(ExcelExportCellType.tableHeader, 7),
            ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 2),
          ],
          [
            'Project #',
            'Asset',
            'Completion Date',
            'Invoice Date',
            'Description',
            'Asset Type',
            'Property',
            'Cost',
            'Client Invoice',
          ],
        ),
      ),
      ...getAssetReactiveSpendReportsRows(isTransparent, report.reports),
      omitNotTransparentColumns(
        isTransparent,
        R.zipWith(
          createExcelExportCell,
          [
            ...R.repeat(ExcelExportCellType.default, 7),
            ...R.repeat(ExcelExportCellType.defaultCurrency, 2),
          ],
          [...R.repeat('', 8), convertCentsToDollars(report.total)],
        ),
      ),
    ],
  };
};
