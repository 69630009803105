import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { entityToOptionByLabelPath } from '@poly/client-utils';

import { useCurrentClientId } from './useCurrentClientId.js';

export const clientHierarchiesQuery = gql`
  query clientHierarchiesQuery($input: PropertyHierarchiesInput!) {
    propertyHierarchies(input: $input) {
      _id
      name
    }
  }
`;

// prepareHierarchyOptions :: { propertyHierarchies: [PropertyHierarchy] } -> [Option]
const prepareHierarchyOptions = R.compose(
  R.map(entityToOptionByLabelPath(['name'])),
  R.propOr([], 'propertyHierarchies'),
);

export const useHierarchyOptions = () => {
  const clientId = useCurrentClientId();

  const { data, loading } = useQuery(clientHierarchiesQuery, {
    variables: { input: { clientId } },
    skip: !clientId,
  });

  const options = prepareHierarchyOptions(data);

  return { options, loading };
};
