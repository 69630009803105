import React from 'react';
import { LoginPageBase } from '@poly/common-ui';
import { setCurrentSentryUser, useCurrentUserQuery } from '@poly/client-utils';
import { parseQuery, useNavigate, useLocation } from '@poly/client-routing';
import { routes } from '../routes.js';

export const APP_TITLE = 'Client Portal';

export function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { previousUrl, forgotPasswordMessage } = parseQuery(location.search);

  const { user } = useCurrentUserQuery();

  const onSuccess = () => {
    setCurrentSentryUser(user);

    if (previousUrl && previousUrl !== routes.login) {
      return navigate(previousUrl);
    }
    return navigate(routes.home);
  };

  const goToForgotPassword = () => navigate(routes.forgotPassword);

  return (
    <LoginPageBase
      title={APP_TITLE}
      onSubmitSuccess={onSuccess}
      goToForgotPassword={goToForgotPassword}
      forgotPasswordMessage={forgotPasswordMessage}
    />
  );
}
