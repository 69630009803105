import { Tabs } from '@poly/site-book';
import React from 'react';
import * as R from 'ramda';
import { Loader, WidgetBlock } from '@poly/site-ui';
import { arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useRouterQuery, useUpdateQueryParams } from '@poly/client-routing';

import { TabsSearchInput } from './TabsSearchInput.js';
import { tabsNames } from '../constants/tabs.js';
import { useClearSearchText } from '../redux/searchText.js';

const TabsItemsContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const showSearchInput = R.includes(R.__, [
  tabsNames.assets,
  tabsNames.projects,
  tabsNames.recurring,
  tabsNames.files,
  tabsNames.pms,
  tabsNames.assets,
]);

export function TabsWithSearchSidebar({
  activeTabsMap,
  defaultTab,
  loading,
  tabs,
  ...props
}) {
  const tabQueryKey = 'tab';
  const queryByKey = useRouterQuery([tabQueryKey]);
  const preparedValue = queryByKey[tabQueryKey] || defaultTab;
  const updateQueryParams = useUpdateQueryParams();
  useClearSearchText();

  const ActiveTab = activeTabsMap[preparedValue] || activeTabsMap[defaultTab];

  const onClick = (e) =>
    updateQueryParams({ [tabQueryKey]: e.target.dataset.tab });

  return (
    <WidgetBlock>
      <Tabs>
        <Tabs.Items>
          <TabsItemsContainer>
            {tabs.map(({ value, title }) => (
              <Tabs.Item
                bold
                key={value}
                data-tab={value}
                active={value === preparedValue}
                onClick={onClick}
              >
                {title}
              </Tabs.Item>
            ))}
          </TabsItemsContainer>
          {showSearchInput(preparedValue) && <TabsSearchInput />}
        </Tabs.Items>
      </Tabs>
      {loading ? <Loader /> : <ActiveTab {...props} />}
    </WidgetBlock>
  );
}

TabsWithSearchSidebar.propTypes = {
  tabs: arrayOf(shape({ value: string.isRequired, title: string.isRequired })),
  loading: bool.isRequired,
  activeTabsMap: objectOf(func),
  defaultTab: string.isRequired,
};
