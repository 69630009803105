import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import { node } from 'prop-types';
import {
  globalSearchReducer,
  paginationReducer,
  imagesGalleryReducer,
  setFileModalReducer,
} from '@poly/site-ui';
import { searchTextReducer } from '../redux/searchText.js';
import { currentClientReducer } from '../redux/currentClient.js';

export const store = createStore(
  combineReducers({
    globalSearch: globalSearchReducer,
    searchText: searchTextReducer,
    paginator: paginationReducer,
    imagesGallery: imagesGalleryReducer,
    fileModal: setFileModalReducer,
    currentClient: currentClientReducer,
  }),
  {},
);

export function StoreProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

StoreProvider.propTypes = {
  children: node.isRequired,
};
