import * as R from 'ramda';
import styled from 'styled-components';
import { Grid, S } from '@poly/site-book';

export const MoneyGridCell = styled(Grid.Cell)`
  justify-content: end;
`;

export const HeaderCell = styled(S)`
  color: #90939d;
`;

export const GridRow = styled(Grid.Row)`
  border-bottom: 1px solid #f5f2f2;
  background-color: ${R.propOr('white', 'bgColor')} !important;
`;

const BoldGridCell = styled(Grid.Cell)`
  font-size: 16px;
  font-weight: bold;
`;

export const TotalInvoiceCell = styled(BoldGridCell)`
  display: flex;
  flex-direction: row;
  justify-content: end;
  grid-row-start: 1;
  grid-column-start: ${R.prop('start')};
  white-space: nowrap;
`;

export const FooterText = styled(HeaderCell)`
  font-size: 15px;
  margin-right: 10px;
`;

export const TextCenter = styled.div`
  text-align: center;
  letter-spacing: 2px;
`;

export const SpendReportHierarchyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridScrollBody = styled(Grid.Body)`
  overflow-y: scroll;
  height: calc(100vh - 315px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
