import { array, oneOfType, string } from 'prop-types';
import React from 'react';
import { insertParamIntoURL } from '@poly/utils';
import { Link } from '@poly/client-routing';
import { routes } from '../routes.js';

export function PropertyLink(property) {
  const href = insertParamIntoURL(
    'propertyId',
    routes.propertyDetails,
    property._id,
  );
  return <Link href={href}>{property.name}</Link>;
}
export function AssetLink({ _id, displayName, target }) {
  const href = insertParamIntoURL('assetId', routes.assetDetails, _id);
  return (
    <Link href={href} target={target}>
      {displayName}
    </Link>
  );
}

AssetLink.propTypes = {
  _id: string.isRequired,
  displayName: oneOfType([string, array]),
  target: string,
};
