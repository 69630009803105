import React from 'react';
import { NotFound } from '@poly/site-ui';
import { Link } from '@poly/client-routing';

import { routes } from '../routes.js';
import { Navigation } from './Navigation.js';
import { HeaderContainer } from '../components/Header.js';

function LinkToMain() {
  return <Link href={routes.home}>Homepage</Link>;
}

export function NotFoundPage() {
  return (
    <>
      <HeaderContainer>
        <Navigation />
      </HeaderContainer>
      <NotFound LinkToMain={LinkToMain} />
    </>
  );
}
