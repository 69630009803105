import { gql, useQuery } from '@apollo/client';
import { endOfCurrentDay } from '@poly/utils';
import * as R from 'ramda';

import { getStartDateOfRange } from '../../constants/common.js';

const assetReactiveSpendTotalQuery = gql`
  query assetProjectsTotalQuery(
    $assetId: ID!
    $dateRange: ReactiveSpendInput!
  ) {
    asset(id: $assetId) {
      _id
      reactiveSpend(input: $dateRange)
    }
  }
`;

// getReactiveSpendTotal :: Object -> Number
const getReactiveSpendTotal = R.pathOr(0, ['asset', 'reactiveSpend']);

export const useAssetReactiveSpendQuery = (assetId, selectedRange) => {
  const startDate = getStartDateOfRange(selectedRange);
  const { data, loading } = useQuery(assetReactiveSpendTotalQuery, {
    variables: {
      assetId,
      dateRange: {
        ...(startDate ? { startDate } : {}),
        endDate: endOfCurrentDay(),
      },
    },
    fetchPolicy: 'network-only',
    skip: !assetId,
  });

  const total = getReactiveSpendTotal(data);

  return {
    total,
    loading,
  };
};
