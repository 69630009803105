/* eslint-disable node/file-extension-in-import */
/* eslint-disable import/extensions */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { initSentry } from '@poly/client-utils';

import { App } from './app.js';

initSentry('client-portal');

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
