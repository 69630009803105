import * as R from 'ramda';
import React from 'react';
import { Link } from '@poly/client-routing';
import styled from 'styled-components';
import { Grid, L, S, Pip } from '@poly/site-book';
import { highlightTextToReactElement } from '@poly/client-utils';
import { insertParamIntoURL } from '@poly/utils';
import {
  setGlobalSearchStringAction,
  toggleGlobalSearchFocusAction,
} from '@poly/site-ui';

import { store } from '../../providers/StoreProvider.js';
import { routes } from '../../routes.js';

const isExist = R.complement(R.anyPass([R.isNil, R.isEmpty]));

const Wrapper = styled(Grid.Cell)`
  grid-row-gap: 10px;
`;

const A = styled(L.withComponent('span'))`
  display: inline-block;
`;

const PROPERTIES_FILTERS = [
  {
    title: 'Active',
    type: 'active',
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Inactive',
    type: 'inactive',
    color: '#ff8c00',
    showPip: true,
  },
];

const linkDispatch = () => {
  store.dispatch(setGlobalSearchStringAction(''));
  store.dispatch(toggleGlobalSearchFocusAction(false));
};

export const projectsDescriptionFormatter = (search) =>
  function (project) {
    const { description, client, property } = project;
    return (
      <Wrapper>
        <S>
          {highlightTextToReactElement(search, description.substring(0, 150))}
        </S>
        <S type="content" bold>
          {highlightTextToReactElement(search, client.name)} -{' '}
          {highlightTextToReactElement(search, property.name)}
        </S>
      </Wrapper>
    );
  };

export const propertiesDescriptionFormatter = (search) =>
  function (property) {
    const { _id: id, name, client, address } = property;
    return (
      <Wrapper>
        <Link
          href={insertParamIntoURL('propertyId', routes.propertyDetails, id)}
          onClick={linkDispatch}
        >
          <A bold>{highlightTextToReactElement(search, name)}</A>
        </Link>
        <S type="content">
          {highlightTextToReactElement(search, R.prop('name', client))}
        </S>
        <S type="content" bold>
          {highlightTextToReactElement(
            search,
            R.prop('formatted_address', address),
          )}
        </S>
      </Wrapper>
    );
  };

export const projectFormatter = (id, search) => (
  <Link
    href={insertParamIntoURL('projectId', routes.projectDetails, id)}
    onClick={linkDispatch}
  >
    <A bold>{isExist(search) ? highlightTextToReactElement(search, id) : id}</A>
  </Link>
);

export const statusFormatter = (search) =>
  function (status) {
    const { title, color } = PROPERTIES_FILTERS.find(
      ({ type }) => status === type,
    );

    return (
      <Pip {...{ color }}>
        <S type="title">
          {isExist(search) ? highlightTextToReactElement(search, title) : title}
        </S>
      </Pip>
    );
  };
