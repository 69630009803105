import { FORGOT_PASSWORD_ROUTE } from '@poly/constants';

export const routes = {
  home: '/',
  login: '/login',
  forgotPassword: FORGOT_PASSWORD_ROUTE,
  changePassword: '/set-password/:token',
  properties: '/properties',
  projectDetails: '/project/:projectId',
  reports: '/reports',
  propertyDetails: '/property/:propertyId',
  spendReport: '/spend-report',
  confirmSatisfactionReport: '/confirm-satisfaction-report',
  assetDetails: '/asset/:assetId',
  assetReactivitySpendReport: '/asset-reactivity-spend-report',
  notFound: '/*',
};
