import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { formatDate, assocBy, forceTitleCase } from '@poly/utils';
import { AbsoluteLoader, Paginator, Table } from '@poly/site-ui';
import {
  useHighlightMatchesBySearch,
  keywordSortQuery,
  useSortableTable,
  commonSortQuery,
} from '@poly/client-utils';
import {
  ProjectPrioritiesUIMap,
  ProjectTypeToNameMap,
  DESC_SORT_ORDER,
  ProjectType,
} from '@poly/constants';

import { useProjectsQuery } from './useProjectsQuery.js';
import { ProjectLink } from '../../components/ProjectLink.js';
import { useSaveDataForTableExport } from '../../components/print-visible-table.js';
import { useClearTableSearch } from '../../hooks/useClearTableSearch.js';
import { getProjectsSearchQuery } from './helpers.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

export function HighlightedCell(text) {
  return <span>{text}</span>;
}

// formatProjectsRows :: [Project] -> [Project]
const formatProjectsRows = R.map(
  R.when(
    R.pathSatisfies(R.isNil, ['serviceType', 'name']),
    R.assocPath(['serviceType', 'name'], ProjectTypeToNameMap[ProjectType.FEE]),
  ),
);

const columns = [
  [
    'WO #',
    R.pick(['projectId', 'highlightedProjectId']),
    ProjectLink,
    keywordSortQuery(['projectId']),
  ],
  [
    'Priority',
    R.compose(R.prop(R.__, ProjectPrioritiesUIMap), R.prop('priority')),
    HighlightedCell,
    commonSortQuery(['priority']),
  ],
  [
    'Service Type',
    R.pathOr(ProjectTypeToNameMap[ProjectType.FEE], ['serviceType', 'name']),
    HighlightedCell,
    keywordSortQuery(['serviceType', 'name']),
  ],
  [
    'Description',
    R.prop('description'),
    HighlightedCell,
    keywordSortQuery(['description']),
  ],
  [
    'Property',
    R.path(['property', 'name']),
    HighlightedCell,
    keywordSortQuery(['property', 'name']),
  ],
  ['Start', R.prop('startDate'), formatDate, commonSortQuery(['startDate'])],
  ['End Date', R.prop('endDate'), formatDate, commonSortQuery(['endDate'])],
  ['Status', R.prop('status'), forceTitleCase, commonSortQuery(['status'])],
];

const useTableProps = (projects) => ({
  rows: projects,
  columns: R.map(R.nth(1), columns),
  headers: R.map(R.nth(0), columns),
  formats: R.map(R.compose(R.defaultTo(R.identity), R.nth(2)), columns),
  valuesToSort: R.map(R.nth(3), columns),
  gridColumns: `
        110px
        minmax(80px, 120px)
        minmax(110px, 150px)
        repeat(2, minmax(100px, 700px))
        minmax(80px, 120px)
        minmax(80px, 160px)
        minmax(80px, 160px)
      `,
});

export function ProjectsTable({ status }) {
  const { sort, sorting, onHeaderCellClick } = useSortableTable({
    column: 2,
    order: DESC_SORT_ORDER,
    tableConfig: columns,
    sortQueryOptionOrder: 3,
  });

  const clientId = useCurrentClientId();

  useClearTableSearch();
  const { projects, loading, total, fetchMore } = useProjectsQuery(
    status,
    'network-only',
    sort,
  );

  const searchTerm = useSelector(R.prop('searchText'));

  const { rows, ...tableProps } = useTableProps(
    R.map(assocBy('highlightedProjectId', R.prop('projectId')), projects),
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    formatProjectsRows,
    [
      'highlightedProjectId',
      ['serviceType', 'name'],
      'description',
      ['property', 'name'],
    ],
    rows,
    true,
  );

  useSaveDataForTableExport({
    total,
    searchTerm,
    fetchMore,
    query: getProjectsSearchQuery(status, clientId),
    rows,
    sort,
    ...tableProps,
  });

  if (loading) {
    return <AbsoluteLoader />;
  }

  return (
    <>
      <Table
        {...tableProps}
        rows={highlightedRows}
        onHeaderCellClick={onHeaderCellClick}
        sorting={{ key: sorting.columnKey, dir: sorting.dir }}
      />
      <Paginator total={total} />
    </>
  );
}

ProjectsTable.propTypes = {
  status: string.isRequired,
};
