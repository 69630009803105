import React from 'react';
import { collectionNames } from '@poly/constants';
import { bool, string } from 'prop-types';
import { updateDetailsFragment } from '@poly/site-ui';
import { gql } from '@apollo/client';

import { tabsNames, tabsTitles } from '../../constants/tabs.js';
import { TabsWithSearchSidebar } from '../../components/Tabs.js';
import { EntityFilesTab } from '../../components/EntityFilesTab.js';
import { EntityUpdatesTab } from '../../components/EntityUpdatesTab.js';
import { EntityProjectsTab } from '../../components/EntityProjectsTab.js';
import {
  getPMProjectsSearchQuery,
  getWOSearchQuery,
} from '../ProjectsPage/helpers.js';

function AssetFilesTab({ assetId }) {
  return (
    <EntityFilesTab
      collection={collectionNames.assets}
      documentId={assetId}
      withAddNewBtn={false}
    />
  );
}

AssetFilesTab.propTypes = {
  assetId: string.isRequired,
};

const assetUpdatesQuery = gql`
  query assetUpdatesQuery(
    $assetId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    asset(id: $assetId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateDetailsFragment}
`;

function AssetUpdatesTab({ assetId }) {
  return (
    <EntityUpdatesTab
      collection={collectionNames.assets}
      documentId={assetId}
      entity="asset"
      query={assetUpdatesQuery}
    />
  );
}

AssetUpdatesTab.propTypes = {
  assetId: string.isRequired,
};

function AssetProjectsTab({ assetId }) {
  return (
    <EntityProjectsTab
      searchQuery={getWOSearchQuery({ term: { assetIds: assetId } })}
    />
  );
}

AssetProjectsTab.propTypes = {
  assetId: string.isRequired,
};

function AssetPMsTab({ assetId }) {
  return (
    <EntityProjectsTab
      searchQuery={getPMProjectsSearchQuery({ term: { assetIds: assetId } })}
    />
  );
}

AssetPMsTab.propTypes = {
  assetId: string.isRequired,
};

const activeTabsMap = {
  [tabsNames.files]: AssetFilesTab,
  [tabsNames.projects]: AssetProjectsTab,
  [tabsNames.pms]: AssetPMsTab,
  [tabsNames.updates]: AssetUpdatesTab,
};

const tabs = [
  { title: tabsTitles.projects, value: tabsNames.projects },
  { title: tabsTitles.pms, value: tabsNames.pms },
  { title: tabsTitles.files, value: tabsNames.files },
  { title: tabsTitles.updates, value: tabsNames.updates },
];

export function AssetTabs({ assetId, loading }) {
  return (
    <TabsWithSearchSidebar
      activeTabsMap={activeTabsMap}
      defaultTab={tabsNames.projects}
      assetId={assetId}
      tabs={tabs}
      loading={loading}
    />
  );
}

AssetTabs.propTypes = {
  assetId: string,
  loading: bool,
};
