import React from 'react';
import { Loader, mapConfigTableProps, Table } from '@poly/site-ui';
import styled from 'styled-components';
import { gql, useLazyQuery } from '@apollo/client';
import * as R from 'ramda';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { ClientInvoiceStatuses } from '@poly/constants';
import { applyPathOrNothingUI, showBase64PDF } from '@poly/client-utils';
import { centsToDollarsWithFormat, formatDate } from '@poly/utils';
import { InvoicesModal } from './InvoicesModal.js';
import { Container, tableProps } from './SupplierInvoicesModal.js';

const statusUIMap = {
  [ClientInvoiceStatuses.PENDING]: 'Pending',
  [ClientInvoiceStatuses.PARTIALLY_PAID]: 'Partially Paid',
  [ClientInvoiceStatuses.PAID]: 'Paid',
  [ClientInvoiceStatuses.VOIDED]: 'Voided',
};

const GET_CLIENT_INVOICE_PDF_QUERY = gql`
  query GET_CLIENT_INVOICE_PDF_QUERY($clientInvoiceId: ID!) {
    getClientInvoicePDF(clientInvoiceId: $clientInvoiceId) {
      pdfBase64
    }
  }
`;

const NumberLinkContainer = styled.div`
  width: 10px;
  height: 15px;
  position: relative;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #436cbe;
  margin: 0;
`;

const LoaderTransparent = styled(Loader)`
  background-color: transparent;
`;

// getPdfUrlFromMutationResult :: { getClientInvoicePDF: { pdfBase64: String } } -> String
const getPdfUrlFromMutationResult = R.path([
  'getClientInvoicePDF',
  'pdfBase64',
]);

function ClientInvoiceNumberLink(invoice) {
  const [getClientInvoicePDF, { loading }] = useLazyQuery(
    GET_CLIENT_INVOICE_PDF_QUERY,
  );

  const onClick = async () => {
    const { data } = await getClientInvoicePDF({
      variables: { clientInvoiceId: invoice._id },
    });

    const invoicePdfBase64String = getPdfUrlFromMutationResult(data);

    if (invoicePdfBase64String) {
      await showBase64PDF(invoicePdfBase64String);
    }
  };

  return loading ? (
    <NumberLinkContainer>
      <LoaderTransparent size={10} />
    </NumberLinkContainer>
  ) : (
    <LinkButton onClick={onClick}>{invoice.number}</LinkButton>
  );
}

const config = [
  ['Invoice #', ClientInvoiceNumberLink],
  ['Total', applyPathOrNothingUI(['amount'], centsToDollarsWithFormat)],
  ['Invoice Date', applyPathOrNothingUI(['invoiceDate'], formatDate)],
  ['Paid Date', applyPathOrNothingUI(['paidAt'], formatDate)],
  ['Status', R.prop('status'), R.prop(R.__, statusUIMap)],
];

export function ClientInvoicesModal({ show, closeModal, invoices }) {
  return (
    <InvoicesModal
      show={show}
      closeModal={closeModal}
      title="Work Order Invoices"
    >
      <Container>
        <Table
          {...tableProps}
          gridColumns="1fr 1fr 1fr 1fr 1fr"
          {...mapConfigTableProps(invoices, config)}
        />
      </Container>
    </InvoicesModal>
  );
}

ClientInvoicesModal.propTypes = {
  show: bool.isRequired,
  closeModal: func.isRequired,
  invoices: arrayOf(
    shape({
      number: number.isRequired,
      amount: number.isRequired,
      status: string.isRequired,
      paidAt: string,
    }),
  ),
};
