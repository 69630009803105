import React from 'react';
import * as R from 'ramda';
import { object, string } from 'prop-types';
import {
  updatesSubscription,
  UpdatesTabWithAddUpdate,
  usePaginatorState,
} from '@poly/site-ui';
import { UpdateTypes } from '@poly/constants';
import { paginationToQueryParams, useReactiveQuery } from '@poly/client-utils';

const { CLIENT_REPORT, CLIENT_UPDATE } = UpdateTypes;

const useUpdatesReactiveQuery = ({ query, entity, documentId }) => {
  const { paginator } = usePaginatorState();
  const { size, currentPage } = paginator;
  const { data, loading, debouncedRefetch } = useReactiveQuery(
    query,
    updatesSubscription,
    {
      queryOptions: {
        variables: {
          documentUpdatesInput: {
            ...paginationToQueryParams({ itemsPerPage: size, currentPage }),
            includeTypes: [CLIENT_REPORT, CLIENT_UPDATE],
          },
          [`${entity}Id`]: documentId,
          fetchPolicy: 'network-only',
        },
      },
      subscriptionOptions: {
        variables: { input: { documentId } },
      },
    },
  );

  return {
    updates: R.pathOr([], [entity, 'documentUpdates', 'hits'], data),
    total: R.pathOr(0, [entity, 'documentUpdates', 'total'], data),
    debouncedRefetch,
    loading,
  };
};

export function EntityUpdatesTab({ query, documentId, collection, entity }) {
  const { updates, total, debouncedRefetch } = useUpdatesReactiveQuery({
    query,
    documentId,
    entity,
  });

  return (
    <UpdatesTabWithAddUpdate
      type={UpdateTypes.CLIENT_UPDATE}
      refetch={debouncedRefetch}
      collection={collection}
      documentId={documentId}
      onUserClick={() => {}}
      updates={updates}
      total={total}
    />
  );
}

EntityUpdatesTab.propTypes = {
  documentId: string.isRequired,
  collection: string.isRequired,
  entity: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
};
