import React from 'react';
import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import { bool, func, string } from 'prop-types';
import { getUIInvoiceNumber, centsToDollarsWithFormat } from '@poly/utils';
import styled from 'styled-components';
import { Loader, mapConfigTableProps, Table } from '@poly/site-ui';
import { NonVirtualInvoiceStatuses } from '@poly/constants';
import { pathOrNothingUI } from '@poly/client-utils';

import { InvoicesModal } from './InvoicesModal.js';

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  flex-direction: column;
`;

const GridRowComponent = styled.section`
  display: grid;
  grid-template-columns: ${R.prop('columns')};
  :not(:last-child) {
    border-bottom: 1px solid rgba(188, 188, 188, 0.2);
  }
  :first-child {
    border-top: 1px solid rgba(188, 188, 188, 0.2);
  }
  padding: 15px 0;
`;

// getInvoiceSupplierName :: Invoice -> Supplier
const getInvoiceSupplierName = pathOrNothingUI(['supplier', 'company', 'name']);

// getInvoiceTotal :: Invoice -> String
const getInvoiceTotal = R.compose(
  centsToDollarsWithFormat,
  R.propOr(0, 'total'),
);

// getInvoiceNumberWithFileUrl :: Invoice -> String
const getInvoiceNumberWithFileUrl = R.applySpec({
  invoiceNumber: getUIInvoiceNumber,
  url: R.path(['invoiceFile', 'url']),
});

function InvoiceFileLink({ url, invoiceNumber }) {
  if (!url) {
    return invoiceNumber;
  }
  return (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {invoiceNumber}
    </a>
  );
}

InvoiceFileLink.propTypes = {
  url: string,
  invoiceNumber: string.isRequired,
};

const config = [
  ['Supplier', getInvoiceSupplierName],
  ['Invoice #', getInvoiceNumberWithFileUrl, InvoiceFileLink],
  ['Amount', getInvoiceTotal],
];

const supplierInvoicesQuery = gql`
  query supplierInvoices(
    $projectId: ID
    $invoicesSearchInput: CollectionSearchParams
  ) {
    project(id: $projectId) {
      searchInvoices(input: $invoicesSearchInput) {
        hits {
          _id
          type
          invoiceNumber
          supplier {
            company {
              name
            }
          }
          total
          invoiceFile {
            url
          }
        }
      }
    }
  }
`;

// getSupplierInvoices :: MutationResult -> [Invoice]
const getSupplierInvoices = R.pathOr([], ['project', 'searchInvoices', 'hits']);

export const tableProps = {
  GridRowComponent,
  withoutBorder: true,
  headerStyles: { paddingLeft: 0 },
};

function InvoicesList({ projectId }) {
  const { data, loading } = useQuery(supplierInvoicesQuery, {
    variables: {
      projectId,
      invoicesSearchInput: {
        query: {
          terms: {
            status: NonVirtualInvoiceStatuses,
          },
        },
      },
    },
    skip: !projectId,
  });

  const invoices = getSupplierInvoices(data);

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <Table
          gridColumns="2fr 1fr 1fr"
          {...tableProps}
          {...mapConfigTableProps(invoices, config)}
        />
      )}
    </Container>
  );
}

InvoicesList.propTypes = {
  projectId: string.isRequired,
};

export function SupplierInvoicesModal({ show, closeModal, projectId }) {
  return (
    <InvoicesModal
      show={show}
      closeModal={closeModal}
      title="Supplier Invoices"
    >
      <InvoicesList projectId={projectId} />
    </InvoicesModal>
  );
}

SupplierInvoicesModal.propTypes = {
  show: bool.isRequired,
  closeModal: func.isRequired,
  projectId: string.isRequired,
};
