import React, { useState } from 'react';
import * as R from 'ramda';
import { Loader } from '@poly/site-ui';

import { AssetReactiveSpendReportHeader } from './AssetReactiveSpendReportHeader.js';
import { LayoutContent } from '../MainLayout.js';
import { useAssetReactiveSpendReport } from './useAssetReactiveSpendReport.js';
import { AssetReactiveSpendReportTable } from './AssetReactiveSpendReportTable.js';
import { useCurrentClientConfig } from '../../hooks/useCurrentClientConfig.js';

export function AssetReactiveSpendReportPage() {
  const [filter, setFilter] = useState({});

  const client = useCurrentClientConfig();

  const isReportEnabled = R.path(
    ['configs', 'clientPortalApp', 'showPMsAndAssets'],
    client,
  );

  const { report, isTransparent, loading } = useAssetReactiveSpendReport(
    filter,
    isReportEnabled,
  );

  return (
    <>
      <AssetReactiveSpendReportHeader
        filter={filter}
        setFilter={setFilter}
        isReportEnabled={isReportEnabled}
        {...{ report, isTransparent }}
      />
      <LayoutContent>
        {loading ? (
          <Loader absolute />
        ) : (
          <AssetReactiveSpendReportTable
            report={report}
            isTransparent={isTransparent}
          />
        )}
      </LayoutContent>
    </>
  );
}
